import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { Button } from '../../../components/Button';
import { InputLabel } from '../../../components/InputLabel';
import { TitleContainer } from '../../../components/Toast/styles';
import { HeaderContainer } from '../../../features/HeaderHearing/styles';
import { useToast } from '../../../hooks/toast';
import { ParticipantesService, TipoParticipanteData } from '../../../services/participantes';
import { hideLoading, showLoading } from '../../../store/modules/login/actions';
import {
  CardContainer,
  Container,
  ContainerBody,
  ContainerButton,
  ContainerCreate,
  ContainerInput,
  ContainerList,
  LabelCard,
  Title,
} from './style';
import Switch from 'react-switch';

export function TiposParticipantes() {
  const [tipoParticipante, setTipoParticipante] = useState('');
  const [idTypeParticipante, setIdTypeParticipante] = useState(0);
  const [listParticipantes, setListParticipantes] = useState<TipoParticipanteData[]>([]);
  const authToken = localStorage.getItem('@Kenta:access_Token');
  const user = localStorage.getItem('@Kenta:user');
  const { addToast } = useToast();
  const dispatch = useDispatch();

  async function cadastrarTipoParticipante() {
    if (!user) {
      return;
    }
    const userJson = JSON.parse(user);
    if (!tipoParticipante || (!userJson && !userJson.customerId)) {
      addToast({
        type: 'error',
        title: 'Erro ao cadastrar',
        description: 'Adicione o dado de tipo de participante corretamente!',
      });
      return;
    }
    dispatch(showLoading());
    const participant = {
      id: idTypeParticipante,
      customerId: userJson.customerId,
      name: tipoParticipante,
      active: true,
    };

    if (participant.id === 0) await ParticipantesService.createParticipante(authToken, participant);
    else await ParticipantesService.editParticipante(authToken, participant);

    searchTiposParticipantes();
    dispatch(hideLoading());
    addToast({
      type: 'success',
      title: 'Registro cadastrado!',
      description: 'O registro foi cadastrado com sucesso.',
    });
  }

  async function searchTiposParticipantes() {
    console.log('token', authToken);
    if (authToken) {
      const { data } = await ParticipantesService.getAllParticipantes(authToken);
      if (data) {
        setListParticipantes(data.data);
      }
    }
    return [];
  }

  const inativarTipoParticipante = async (e: boolean, idParticipante: number) => {
    if (!user) {
      return;
    }
    const userJson = JSON.parse(user);
    if (!userJson && !userJson.customerId) {
      addToast({
        type: 'error',
        title: 'Erro ao cadastrar',
        description: 'Adicione o dado de tipo de participante corretamente!',
      });
      return;
    }
    dispatch(showLoading());
    const participant = {
      id: idTypeParticipante,
      customerId: userJson.customerId,
      name: tipoParticipante,
      active: true,
    };
    console.log('');
    if (participant.id === 0) await ParticipantesService.ativarInativarTipoParticipante(authToken, idParticipante, !e);
    else await ParticipantesService.editParticipante(authToken, participant);

    searchTiposParticipantes();
    dispatch(hideLoading());
    addToast({
      type: 'success',
      title: 'Registro cadastrado!',
      description: 'O registro atualizado com sucesso.',
    });
  };

  useEffect(() => {
    searchTiposParticipantes();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <Title>Tipos de Participantes</Title>
        </TitleContainer>
      </HeaderContainer>
      <ContainerBody>
        <ContainerCreate>
          <ContainerInput>
            <InputLabel
              label='Criar tipo de participante'
              value={tipoParticipante}
              onChange={(e) => {
                setTipoParticipante(e);
              }}
            />
          </ContainerInput>
          <ContainerButton>
            <Button key={'button_participante'} color='forth' onClick={cadastrarTipoParticipante}>
              Cadastrar
            </Button>
          </ContainerButton>
        </ContainerCreate>
        <ContainerList>
          {listParticipantes.length > 0 &&
            listParticipantes.map((item) => {
              return (
                <CardContainer
                  key={Math.random()}
                  onClick={() => {
                    setIdTypeParticipante(item.id);
                    setTipoParticipante(item.name);
                  }}>
                  <LabelCard>{item.name}</LabelCard>
                  <Switch onChange={(e) => inativarTipoParticipante(e, item.id)} checked={item.active} />
                </CardContainer>
              );
            })}
        </ContainerList>
      </ContainerBody>
    </Container>
  );
}
