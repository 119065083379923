import styled, { css } from 'styled-components';

export const Container = styled.div`
  width: 500px;
  height: 81.8vh;
  background: #fff;
  margin: 0;
  padding: 0;
  margin: 0;
  padding: 0;
`;

export const HeaderContainer = styled.nav`
  ul {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
    margin: 0;
    padding: 0;
    height: 60px;
    background: white;
    text-decoration: none;
    border-bottom: 1px solid #cbcbcb;
  }
`;

export const ItemListMenu = styled.li`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 60px;
  font-size: 14px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 600;

  ${(props: { selected?: boolean }) =>
    props.selected &&
    css`
      border-bottom: 4px solid #e98d0b;
    `}

  &:hover {
    cursor: pointer;
    border-bottom: 4px solid #e98d0b;
  }
`;

export const Content = styled.div`
  width: 100%;
  height: 70vh;
  background: red;
`;
