import styled from 'styled-components';

export const ContainerSchedule = styled.div`
  //padding: 4px 8px;
  display: inline-table;
  vertical-align: middle;
  /* width: 10px;
  height: 10px; */
  /* width: 30px;
  height: 30px; */

  width: ${(props) => props.itemType};
  height: ${(props) => props.itemType};

  background: ${(props) => props.color};
  border-radius: ${(props) => props.itemProp};
`;

export const Scheduled = styled.span`
  /* 
  font-family: 'inter', Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: ${(props) => props.color}; */

  display: table-cell;
  vertical-align: middle;
  text-align: center;
  color: ${(props) => props.color};
  font-size: 15px;
`;

// export const circle = styled.div`
//   display: inline-table;
//   vertical-align: middle;
//   width: 40px;
//   height: 40px;

//   background-color: #bbb;
//   border-radius: 50%;
// `;

// export const circleContent = styled.div`
//   display: table-cell;
//   vertical-align: middle;
//   text-align: center;
// `;
