import { createGlobalStyle } from 'styled-components';

const GlobalStyle = createGlobalStyle`
  body {
    margin: 0;
    padding: 0;
    font-family: "Inter", Open-Sans, Helvetica, Sans-Serif;
    overflow-x: hidden;
    overflow-y: hidden;
  }

  div {
      /* width */
      ::-webkit-scrollbar {
      width: 4px;
    }

    /* Track */
    ::-webkit-scrollbar-track {
      background: #f1f1f1;
      margin-top: 16px;
    }

    /* Handle */
    ::-webkit-scrollbar-thumb {
      background: #1d9bf0;
      border-radius: 2px;
    }

    /* Handle on hover */
    ::-webkit-scrollbar-thumb:hover {
      background: #555;
    }
  }

  button:hover {
    cursor: pointer;
  }

`;

export default GlobalStyle;
