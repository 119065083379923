import React, { MutableRefObject, useState } from 'react';
import HearingInformation from './HearingInformation';
import { MarkingContent } from './Marking';

import { Container, HeaderContainer, ItemListMenu } from './styles';
import { TranscriptionComponent } from './Transcription';

type IHearingDetailsSidebar = {
  marking: any,
  manualTranscriptionRef: MutableRefObject<any>,
  setOpenModal: (bool: boolean) => unknown,
  onEditMarking: (marking: any) => unknown,
  onDeleteMarking: (marking: any) => unknown,
  procedureItem: any,
  dataMarking: Array<any>,
  currentIndex: number,
  setCurrentIndex: (number: number) => unknown,
};

const HearingDetailsSidebar: React.FC<IHearingDetailsSidebar> = ({
  marking,
  onDeleteMarking,
  onEditMarking,
  procedureItem,
  setOpenModal,
  dataMarking,
  currentIndex,
  setCurrentIndex,
  manualTranscriptionRef,
}) => {
  const [selectedMenu, setSelectedMenu] = useState('1');

  const selectedPage = () => {
    switch (selectedMenu) {
      case '1':
        return <HearingInformation procedureItem={procedureItem} />;
      case '2':
        return (
          <MarkingContent
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            onDeleteMarking={onDeleteMarking}
            onEditMarking={onEditMarking}
            data={dataMarking}
            setOpenModal={setOpenModal}
            marking={marking}
          />
        );
      case '3':
        return <TranscriptionComponent manualTranscriptionRef={manualTranscriptionRef} />;
    }
  };

  return (
    <Container>
      <HeaderContainer>
        <ul>
          <ItemListMenu selected={selectedMenu === '1'} onClick={() => setSelectedMenu('1')}>
            Informações
          </ItemListMenu>
          <ItemListMenu selected={selectedMenu === '2'} onClick={() => setSelectedMenu('2')}>
            Marcações
          </ItemListMenu>
          <ItemListMenu selected={selectedMenu === '3'} onClick={() => setSelectedMenu('3')}>
            Transcrições
          </ItemListMenu>
        </ul>
      </HeaderContainer>
      {selectedPage()}
    </Container>
  );
};

export default HearingDetailsSidebar;
