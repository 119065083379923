import { LOGIN_USER, SELECT_PROFILE } from "../actions/actios.types";

const INITIAL_STATE: any = {
  user: null,
  profile: null,
};

const user = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case LOGIN_USER:
      return {
        ...state,
        user: action.user,
      };
    case SELECT_PROFILE:
      return {
        ...state,
        profile: action.profile,
      };
    default:
      return state;
  }
};

export default user;
