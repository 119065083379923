import { format } from 'date-fns';
import React from 'react';
import { HeaderProcedure } from '../HeaderProcedure';
import { ProcedureItem } from '../ProcedureItem';
import { Container, Empty } from './styles';

export type IProcedureList = {
  data: Array<any>,
  title?: string,
  currentIndex: number,
  setCurrentIndex: (index: number) => unknown,
  setModalHearing: (boolean: boolean) => unknown,
  addButtonClick?: () => unknown,
  deleteButtonClick?: () => unknown,
  setItemData: (data: any) => any | null,
};

export const ProcedureList: React.FC<IProcedureList> = ({
  data = [],
  title,
  addButtonClick,
  deleteButtonClick,
  setCurrentIndex,
  currentIndex,
  setItemData,
  setModalHearing,
}) => {
  const selectProcedure = (index: number, data: object) => {
    if (currentIndex !== index) {
      setCurrentIndex(index);
      setItemData(data);
      setModalHearing(false);
    } else {
      setCurrentIndex(-1);
      setItemData(null);
      setModalHearing(false);
    }
  };

  return (
    <>
      <HeaderProcedure
        title={title}
        AddButtonClick={addButtonClick}
        DeleteButtonClick={deleteButtonClick ? deleteButtonClick : undefined}
      />
      <Container>
        {data.length > 0 ? (
          data.map((item, index) => (
            <ProcedureItem
              onClick={() => selectProcedure(index, item)}
              key={item.id || index}
              selected={currentIndex === index || false}
              title={item.procedureNumber ? item.procedureNumber : `Sem titulo ${index + 1}`}
              description={item.procedureCustomerSourceName ? item.procedureCustomerSourceName : 'Sem descrição'}
              date={format(new Date(item.expectedDate), 'dd/MM/yyyy')}
              isAgended={true}
            />
          ))
        ) : (
          <Empty>Nenhum procedimento cadastrado</Empty>
        )}
      </Container>
    </>
  );
};
