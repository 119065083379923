import React from 'react';
import { CircleBadge } from '../../Badges/Circle';


import { Container, Label, LabelContainer, Input } from './styles';

type IInput = {
  style?: object,
  label: string,
  disabled?: boolean,
  required?: boolean,
  placeholder?: string,
  defaultValue?: string,
  onChange: (e: string) => unknown,
};

export const InputComponent: React.FC<IInput> = ({
  style,
  label,
  disabled = false,
  required = false,
  placeholder,
  defaultValue,
  onChange,
}) => {
  return (
    <Container style={style}>
      <LabelContainer>
        <Label>{label}</Label>
        {required && <CircleBadge color='#e98d0b' radius='50px' widthHeigt='10px' />}
      </LabelContainer>
      <Input
        defaultValue={defaultValue}
        placeholder={placeholder ? placeholder : label}
        disabled={disabled}
        onChange={(e) => onChange(e.target.value)}
        type='text'
      />
    </Container>
  );
};
