import React, { useEffect, useRef, useState, MutableRefObject } from 'react';
import PlayerControl from '../PlayerControl';

import { Container, VideoContainer, VideoPlayerComponent } from './styles';

function usePlayerState($videoPlayer: MutableRefObject<HTMLVideoElement>) {
  const [playerState, setPlayerState] = useState({
    playing: false,
    progress: 0,
    currentTime: '00:00:00' || 0,
    duration: '00:00:00' || 0,
    secondsCurrentTime: 0,
    secondsDuration: 0,
    volume: 1,
    playbackRate: 1,
    marking: null,
  });

  function toggleVideoPlay() {
    setPlayerState({
      ...playerState,
      playing: !playerState.playing,
    });

    if ($videoPlayer.current && playerState.playing) {
      $videoPlayer.current.pause();
    } else {
      $videoPlayer.current.play();
    }
  }

  function toggleVideoProgress(currentTime: number, percent: number) {
    const totalSeconds = $videoPlayer.current.duration;
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = Math.floor(totalSeconds % 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    const totalSecondsCurrent = currentTime;
    const totalMinutesCurrent = Math.floor(totalSecondsCurrent / 60);

    const secondsCurrent = Math.floor(totalSecondsCurrent % 60);
    const minutesCurrent = totalMinutesCurrent % 60;
    const hoursCurrent = Math.floor(totalMinutesCurrent / 60);

    setPlayerState({
      ...playerState,
      progress: percent,
      secondsCurrentTime: currentTime - 0.05,
      secondsDuration: totalSeconds,
      duration: `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${
        seconds < 10 ? '0' + seconds : seconds
      }`,
      currentTime: `${hoursCurrent < 10 ? '0' + hoursCurrent : hoursCurrent}:${
        minutesCurrent < 10 ? '0' + minutesCurrent : minutesCurrent
      }:${secondsCurrent < 10 ? '0' + secondsCurrent : secondsCurrent}`,
    });

    if (percent === 100) {
      toggleVideoPlay();
      $videoPlayer.current.currentTime = 0;
    }
  }

  function toggleFullscreen() {
    $videoPlayer.current.requestFullscreen();
  }

  function toggleChangeProgress(percent: number) {
    const currentTime = ($videoPlayer.current.duration / 100) * percent;

    $videoPlayer.current.currentTime = currentTime;
  }

  function toggleFiveSeconds(type: 'advance' | 'back') {
    switch (type) {
      case 'advance':
        $videoPlayer.current.currentTime += 5;
        break;
      case 'back':
        $videoPlayer.current.currentTime -= 5;
        break;
    }
  }

  function toggleVolume(volume: number) {
    setPlayerState({ ...playerState, volume });
    $videoPlayer.current.volume = volume;
  }

  function toggleRate(rate: number) {
    $videoPlayer.current.playbackRate = rate;
    setPlayerState({ ...playerState, playbackRate: rate });
  }

  function toggleMarkingTime(marking: number) {
    $videoPlayer.current.currentTime = marking;
  }

  return {
    playerState,
    toggleVideoPlay,
    toggleVideoProgress,
    toggleFullscreen,
    toggleChangeProgress,
    toggleFiveSeconds,
    toggleVolume,
    toggleRate,
    toggleMarkingTime,
  };
}

type IVideoPlayer = {
  setMarking: any,
  setCurrenctTime?: (time: number) => unknown,
};

export const VideoPlayer: React.FC<IVideoPlayer> = ({ setMarking }) => {
  const $videoPlayer = useRef<any>(null);
  const {
    playerState,
    toggleVideoProgress,
    toggleVideoPlay,
    toggleFullscreen,
    toggleChangeProgress,
    toggleMarkingTime,
    toggleFiveSeconds,
    toggleVolume,
    toggleRate,
  } = usePlayerState($videoPlayer);

  useEffect(() => {
    setMarking({ toggleMarkingTime, toggleRate, toggleVideoPlay, playerState });
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [playerState.playing, playerState.currentTime, playerState]);

  return (
    <Container>
      <VideoContainer>
        <VideoPlayerComponent
          ref={$videoPlayer}
          src='http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'
          onTimeUpdate={() => {
            const currentPorcetage = ($videoPlayer.current.currentTime / $videoPlayer.current.duration) * 100;
            toggleVideoProgress($videoPlayer.current.currentTime, currentPorcetage);
          }}
          controls>
          Desculpe, seu navegador não tem suporte o player de vídeo.
        </VideoPlayerComponent>
      </VideoContainer>
      <PlayerControl
        playerState={playerState}
        toggleChangeProgress={toggleChangeProgress}
        toggleVideoPlay={toggleVideoPlay}
        toggleFullscreen={toggleFullscreen}
        toggleFiveSeconds={toggleFiveSeconds}
        toggleVolume={toggleVolume}
        toggleRate={toggleRate}
      />
    </Container>
  );
};
