import React from 'react';
import { Routes, Route } from 'react-router-dom';
import { LoadingToast } from '../components/LoadingToast';
import { Administration } from '../pages/app/administration';
import { HearingDetails } from '../pages/app/hearingDetails';
import { ProcedureScreen } from '../pages/app/procedure';
import { SignIn } from '../pages/auth/signIn';
import { ProtectedRoute } from './ProtectoredRoute';

export function Router() {
  return (
    <>
      <LoadingToast />
      <Routes>
        <Route path='/' element={<SignIn />} />
        <Route
          path='/*'
          element={
            <ProtectedRoute>
              <div />
            </ProtectedRoute>
          }
        />
        <Route
          path='/dashboard/procedure'
          element={
            <ProtectedRoute>
              <ProcedureScreen />
            </ProtectedRoute>
          }
        />
        <Route
          path='/dashboard/administracao/*'
          element={
            <ProtectedRoute>
              <Administration />
            </ProtectedRoute>
          }
        />
        <Route
          path='/dashboard/procedure/hearingDetails'
          element={
            <ProtectedRoute>
              <HearingDetails />
            </ProtectedRoute>
          }
        />
      </Routes>
    </>
  );
}
