import styled, { css } from 'styled-components';
import { shade } from 'polished';
interface IProps {
  playing: boolean;
}

export const PlayerContainer = styled.div`
  width: 100%;
  height: 90px;
  background: #3e424e;
`;

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  height: 86px;
  width: 100%;
`;

export const LeftButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 42px;
`;
export const CenterButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-left: 30px;
`;
export const RightButton = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
  margin-right: 42px;
`;

export const TimeButton = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 40px;
  height: 40px;
  border-radius: 50%;
  border: ${(props) => (props.disabled ? '2px solid rgba(120, 135, 169, 0.5)' : '2px solid #1D9BF0')};
  background: none;

  &:hover {
    ${(props) =>
      !props.disabled &&
      css`
        background: ${shade(0.2, '#1D9BF0')};
        border: 2px solid #fff;
      `}

    ${(props) =>
      props.disabled &&
      css`
        cursor: default;
      `}
  }
`;
export const PlayButton =
  styled.button <
  IProps >
  `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 48px;
  height: 48px;
  border-radius: 50%;
  background: ${(props) => (props.playing ? 'none' : '#1D9BF0')} ;
  border:${(props) => (props.playing ? '2px solid #e98d0b' : '2px solid #fff')};
  margin: 0 16px;

  &:hover {
    background: ${(props) => (props.playing ? 'none' : shade(0.2, '#1D9BF0'))};
  }
`;

export const Time = styled.span`
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 14px;
  color: #fff;
  margin-left: 30px;
`;

export const ButtonIcon = styled.button`
  cursor: pointer;
  background: none;
  border: 0;
`;

export const ButtonIconVolume = styled.div`
  cursor: pointer;
  display: flex;
  background: none;
  border: 0;
  transition: 0.3s;

  img {
    width: 20px;
    height: 18px;
  }

  input {
    accent-color: #e98d0b;
  }

  #volume {
    position: absolute;
    margin-left: 20px;
    opacity: 0;
    display: none;
  }

  &:hover {
    #volume {
      transition: 0.3s;
      opacity: 1;
      display: block;
    }
  }
`;

export const PlaybackRateMenu = styled.div`
  position: absolute;
  margin-top: -200px;
  margin-left: -110px;
  width: 120px;
  background: #00000088;
  border-radius: 8px;
  position: absolute;
  padding: 0 10px;

  strong {
    display: flex;
    align-items: center;
    justify-content: flex-end;
    width: 100%;
    color: #fff;
    padding: 4px;
    font-size: 14px;
    cursor: pointer;
  }
`;

export const PlaybackRate = styled.span`
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 16px;
  margin-right: 12px;
  color: #fff;
  cursor: pointer;

  &:hover {
    #playbackList {
      transition: 0.3s;
      opacity: 1;
      display: block;
    }
  }
`;

export const PlaybackRateContainer = styled.div`
  cursor: pointer;
  display: flex;
  background: none;
  border: 0;
  transition: 0.3s;

  #playbackList {
    display: none;
    opacity: 0;
  }

  &:hover {
    #playbackList {
      display: block;
      opacity: 1;
    }
  }
`;
