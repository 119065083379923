import { CLEAN_ATTACHMENT_LIST, CREATE_NEW_ATTACHMENT, LIST_ATTACHMENTS } from '../actions/actios.types';

const INITIAL_STATE: any = {
  attachment: [],
};

const attachment = (state = INITIAL_STATE, action: any) => {
  switch (action.type) {
    case CREATE_NEW_ATTACHMENT:
      return {
        ...state,
        attachment: [...state.attachment, action.attachment],
      };
    case LIST_ATTACHMENTS:
      return state.attachment;

    case CLEAN_ATTACHMENT_LIST:
      return {
        ...state,
        attachment: [],
      };

    default:
      return state;
  }
};

export default attachment;
