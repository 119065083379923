import styled from 'styled-components';

export const Container = styled.div`
  width: 700px;
  background: #fff;
  padding: 24px;
  height: 90vh;
  overflow-y: auto;
  box-shadow: -4px 4px 12px rgba(0, 0, 0, 0.12);
`;

export const ContainerTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;

  .closeButton {
    cursor: pointer;
  }
`;

export const Title = styled.h3`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 700;
  font-size: 18px;
  line-height: 22px;
  padding-left: 30px;
  color: black;
`;

export const ContainerBody = styled.div`
  width: 100%;
  height: 80%;
  background-color: #fff;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: end;
`;

export const styleDelete = {
  border: 1,
  borderStyle: 'solid',
  borderColor: '#FFF',
  borderRadius: '20px',
  marginRight: 14,
  cursor: 'pointer',
};

export const customStyles = {
  content: {
    border: 'none',
    backgroundColor: '#FFF',
    width: '20%',
    height: '100%',
    inset: 'auto',
    padding: 0,
    top: 0,
    left: 0,
    borderRadius: 0,
    // position: 'initial',
  },
  overlay: {
    backgroundColor: 'rgba(35, 35, 35, 0.75)',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};

export const styleImage = {
  top: 0,
  right: 0,
  margin: 26,
  cursor: 'pointer',
};

export const Button = styled.button`
  padding: 10px 15px;
  cursor: pointer;
  border: none;
  border-radius: 50px;
  font-weight: 500;
  outline: none;
  transition: all 0.2s;
  margin-right: 14px;
  background-color: #1d9bf0;
  color: #fff;
`;
