import styled from 'styled-components';

interface IProps {
  selected: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 304px;
  height: 45px;
  background: ${(props: IProps) => (props.selected ? '#E4F1FA' : '#ECF0F3')};
  border: ${(props: IProps) => (props.selected ? '2px solid #1d9bf0' : '2px solid #ECF0F3')};
  border-radius: 8px;
  padding: 20px;
  margin: 4px 0;
  cursor: pointer;
`;

export const AssetsContainer = styled.div`
  width: 32px;
`;

export const Checkbox = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  background: ${(props: IProps) => (props.selected ? '#1D9BF0' : '#FFF')};
  border: ${(props: IProps) => (props.selected ? '1px solid #1D9BF0' : '1px solid #FFF')};
  border-radius: 4px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: column;
  margin-left: 8px;
`;

export const Title = styled.span`
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-size: 14px;
  font-weight: 500;
  color: #000;
`;

export const Description = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  margin-top: 10.5px;
  color: #666;
`;

export const DateContainer = styled.div`
  display: flex;
  align-items: flex-end;
  flex-direction: column;
  margin-left: 29px;
`;

export const DateTitle = styled.span`
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
`;

export const ContainerSchedule = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 4px 8px;
  background: #30cd82;
  border-radius: 20px;
  margin-top: 11px;
`;

export const Scheduled = styled.span`
  font-size: 10px;
  font-family: 'inter', Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: #fff;
  font-style: normal;
`;

export const TextContainer = styled.div`
  display: flex;
  flex-direction: row;
`;
