import styled from 'styled-components';

export const Container = styled.div`
  width: 700px;
  background: #ecf0f3;
  padding: 24px;
  height: 90vh;
  overflow-y: auto;

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #1d9bf0;
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
    overflow-y: auto;
  }
`;

export const ItemsContainer = styled.div`
  width: 100%;
  border-bottom: 1px solid #d1dbe3;
  padding-bottom: 24px;
  margin-bottom: 26.5px;
`;

export const AttachmentEmpty = styled.div`
  font-size: 14px;
  color: #7887a9;
  margin-top: 24px;
  font-family: 'Inter Tight', Arial, Helvetica, sans-serif;
`;
