import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  height: 6px;
  background: #666;
`;

export const Progress = styled.input`
  width: 100%;
  height: 6px;
  accent-color: #e98d0b;

  cursor: pointer;
`;
