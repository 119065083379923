import React, { useState, useEffect } from 'react';
import { Container, DropDownContainer, DropDownHeader, DropDownList, DropDownListContainer, ListItem } from './style';
import ArrowDown from '../../assets/svg/arrow-down.svg';

interface PoliceUnit {
  customerId: number;
  uf: string;
  customer: string;
  containerName: string;
  root: string;
  username: string;
  unitId: number;
  policeUnit: string;
  profileId: number;
  profile: string;
  expireIn: string;
}

export const SelecionarUnidade = (props: { unidades: PoliceUnit[] }) => {
  const [isOpen, setIsOpen] = useState(false);
  const [selectedOption, setSelectedOption] = useState<PoliceUnit | null>(props.unidades[0]);
  const toggling = () => setIsOpen(!isOpen);

  const onOptionClicked = (value: any) => () => {
    setSelectedOption(value);
    setIsOpen(false);
    localStorage.setItem('@Kenta:unidade_selecionada', JSON.stringify(value));
  };

  useEffect(() => {
    if (props.unidades && props.unidades.length > 0) {
      localStorage.setItem('@Kenta:unidade_selecionada', JSON.stringify(props.unidades[0]));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // const onUnidadeClick =

  return (
    <Container>
      <DropDownContainer>
        <DropDownHeader onClick={toggling}>{selectedOption ? selectedOption.policeUnit : ''}</DropDownHeader>
        {isOpen && (
          <DropDownListContainer>
            <DropDownList>
              {props.unidades.map((option) => (
                <ListItem onClick={onOptionClicked(option)} key={String(Math.random()) + option}>
                  {option.policeUnit}
                </ListItem>
              ))}
            </DropDownList>
          </DropDownListContainer>
        )}
      </DropDownContainer>
      <img alt=''className='icon' src={ArrowDown} onClick={toggling} style={{ cursor: 'pointer' }} />
    </Container>
  );
};
