import React, { useEffect, useState } from 'react';
import { Dropdown } from '../../components/Dropdown';
import { InputDate } from '../../components/InputDate';
import { InputLabel } from '../../components/InputLabel';
import api from '../../services/api';
import { Container, ContainerInputs, ContainerInputsDate } from './style';

export type IProcedureType = {
  errorFields: object,
  isEdit?: boolean,
  procedureNumber?: string,
  editProcedureTypeName?: string,
  editProcedureOriginName?: string,
  itemData?: any,
  setProcedureNumber: (e: string) => unknown,
  setDate: (e: string | null) => unknown,
  setFinalDate: (e: string | null) => unknown,
  setProcedureTypeId: (e: number) => unknown,
  setProcedureOriginId: (e: number) => unknown,
};

export const ProcedureDetail: React.FC<IProcedureType> = ({
  procedureNumber,
  setProcedureNumber,
  editProcedureTypeName,
  editProcedureOriginName,
  setDate,
  setFinalDate,
  setProcedureTypeId,
  setProcedureOriginId,
  itemData,
}): JSX.Element => {
  const [procedureTypeList, setProcedureTypeList] = useState([]);
  const [procedureOriginList, setProcedureOriginList] = useState([]);
  const [expectedDate, setExpectedDate] = useState('');
  const [deadLineDate, setDeadLineDate] = useState('');

  const user = localStorage.getItem('@Kenta:user');

  useEffect(() => {
    (async () => {
      if (user) {
        if (itemData) {
          const [expected] = itemData.expectedDate.split('T');
          const [dead] = itemData.deadLineDate.split('T');

          setExpectedDate(expected);
          setDeadLineDate(dead);
        }

        const user_parsed = JSON.parse(user);

        const { data: type } = await api.get(
          `/ProcedureCustomerType/GetAllByCustomerId?customerId=${user_parsed.customerId}`,
        );

        const { data: origin } = await api.get(
          `/ProcedureCustomerSource/GetAllByCustomerId?customerId=${user_parsed.customerId}`,
        );

        setProcedureTypeList(type.data);
        setProcedureOriginList(origin.data);
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <ContainerInputs>
        <InputLabel
          value={procedureNumber}
          onChange={(e) => setProcedureNumber(e)}
          type='number'
          label='Número do procedimento:*'
        />
      </ContainerInputs>
      <ContainerInputsDate>
        <InputDate type='date' default={expectedDate} setDate={setDate} label='Data de abertura*' />
        <InputDate type='date' default={deadLineDate} setDate={setFinalDate} label='Prazo:*' />
      </ContainerInputsDate>
      <ContainerInputs style={{ width: '82%', marginTop: 10 }}>
        <Dropdown
          editProcedure={editProcedureTypeName}
          setSelected={setProcedureTypeId}
          labelName='Tipo de procedimento:*'
          options={procedureTypeList}
        />
      </ContainerInputs>
      <ContainerInputs style={{ width: '82%', marginTop: 10 }}>
        <Dropdown
          editProcedure={editProcedureOriginName}
          setSelected={setProcedureOriginId}
          options={procedureOriginList}
          labelName='Origem:*'
        />
      </ContainerInputs>
    </Container>
  );
};
