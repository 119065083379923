import axios from 'axios';
export const baseURL = 'https://digitalinquirysvprd-dev.azurewebsites.net/api';
export const storageBaseUrl = 'https://kentaproductsstg.blob.core.windows.net';
//export const baseURL = 'https://localhost:44311/api';

const api = axios.create({
  baseURL,
  timeout: 20000,
});
export default api;

async function refreshToken({ access_Token, refresh_Token }: any) {
  const { data } = await axios.post(baseURL + '/token/refresh', {
    access_Token,
    refresh_Token,
  });

  return {
    new_refresh_Token: data.data.refresh_Token,
    new_access_Token: data.data.access_Token,
  };
}

api.interceptors.request.use(async (config) => {
  const dNow = new Date();

  const expireIn = localStorage.getItem('@Kenta:expireIn');
  const access_Token = localStorage.getItem('@Kenta:access_Token');
  const refresh_Token = localStorage.getItem('@Kenta:refresh_Token');
  let token = localStorage.getItem('@Kenta:access_Token');
  let refresh = false;
  const expired = expireIn ? dNow >= new Date(expireIn) : false;
  if (access_Token && refresh_Token && expired) {
    refresh = true;
  }

  if (refresh) {
    refresh = false;
    const dtNow = new Date();

    localStorage.setItem('@Kenta:expireIn', String(new Date(dtNow.getTime() + 30 * 60 * 1000)));
    const { new_refresh_Token, new_access_Token } = await refreshToken({
      access_Token,
      refresh_Token,
    });

    localStorage.setItem('@Kenta:access_Token', new_access_Token);
    localStorage.setItem('@Kenta:refresh_Token', new_refresh_Token);

    token = new_access_Token;

    config.headers = {
      Authorization: `Bearer ${token}`,
    };

    return config;
  }

  config.headers = {
    Authorization: `Bearer ${token}`,
  };

  return config;
});
