import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 10px;
  align-items: center;
  width: 100%;
  height: 100%;
  padding: 5%;
`;

export const Hr = styled.hr`
  width: 100%;
`;

export const Label = styled.label`
  font-size: 14px;
  cursor: pointer;
`;

export const Line = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  vertical-align: middle;
  align-items: initial;

  padding-top: 10px;
  padding-right: 0px;
  padding-bottom: 10px;
  padding-left: 0px;
`;

export const VideoContainer = styled.div`
  padding-bottom: 10%;
`;

export const Icon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 20px;
  padding-right: 15px;
`;
