import styled from 'styled-components';

interface IProps {
  error?: boolean;
}

export const Container = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
`;

export const Label = styled.label`
  width: 100%;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 15px;
  color: #7887a9;
  padding: 6px;
  margin-left: -16px;
`;

export const Input = styled.input`
  width: 100%;
  border: ${(props: IProps) => (props.error ? '2px solid #e83845' : '2px solid #cccccc')};
  border-radius: 4px;
  height: 40px;
  padding-left: 14px;
  font-size: 16px;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
