import React from 'react';
import { HearingItem } from './item';
import { HearingListContainer } from './styles';
import { format } from 'date-fns';

interface HearingListI {
  hearingList: Array<any>;
  openModal: boolean;
  setOpenModal: (hearingItem: object) => any;
  //edit
  setIsEditHearing: (attribute: boolean) => any;
  onDeleteItem: (id: number) => unknown;
  setItemData: (data: any) => any | null;
}

export const HearingList = ({ hearingList, onDeleteItem, openModal, setOpenModal }: HearingListI) => {
  return (
    <HearingListContainer>
      {hearingList.map((item: any) =>
        item.active ? (
          <HearingItem
            openModal={openModal}
            item={item}
            setOpenModal={setOpenModal}
            onDeleteItem={onDeleteItem}
            key={item.id}
            id={item.id}
            name={item.attendantName}
            typeAttendant={item.attendantRole}
            date={format(new Date(item.expectedDate), 'dd/MM/yyyy')}
            timeVideo='05:53'
            statusText='Aguardando'
            statusType='success'
          />
        ) : null,
      )}
    </HearingListContainer>
  );
};
