import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../../store/modules/login/actions';
import { Hearing } from '../../../components/HeaderHearing';
import { ProcedureList } from '../../../components/ProcedureList';
import { RightSidebar } from '../../../components/RightSidebar';
import api from '../../../services/api';
import { Container, ProcedureContainer } from './styles';
import ModalRight from '../../../components/ModalRight';
import { ProcedureDetail } from '../../../features/ProcedureDetail';
import { formatYmd } from '../../../utils/format';
import { useToast } from '../../../hooks/toast';
import { v4 as uuidv4 } from 'uuid';
import { CreateEditHearing } from '../../../components/Hearing/CreateEdit';

import ModalRightDetail from '../../../components/ModalRight/Detail/detail';
import { HearingDetail } from '../../../components/Hearing/HearingDetailsModalRight';
import { HearingService } from '../../../services/hearing';

interface IProcedure {
  id?: number;
  guid: string;
  expectedDate: string | Date | null;
  deadLineDate: string | Date | null;
  procedureNumber: string;
  procedureCustomerTypeId: number;
  procedureCustomerSourceId: number;
  policeUnitId: number | undefined;
  description: string;
  status: string;
  active: boolean;
}

export const ProcedureScreen: React.FC = () => {
  const policeUnit = localStorage.getItem('@Kenta:unidade_selecionada');

  const [procedureNumber, setProcedureNumber] = useState<string>('');
  const [finalDate, setFinalDate] = useState<string | null>(null);
  const [procedureTypeId, setProcedureTypeId] = useState<number>();
  const [procedureOriginId, setProcedureOriginId] = useState<number>();
  const [policeUnitId, setPoliceUnitId] = useState<number | undefined>();

  const [date, setDate] = useState<string | null>(null);

  const [procedureList, setProcedureList] = useState<Array<any>>([]);
  const [itemData, setItemData] = useState<any | null>(null);
  const [editProcedureTypeName, setEditProcedureTypeName] = useState('');
  const [editProcedureOriginName, setEditProcedureOriginName] = useState('');
  const [currentIndex, setCurrentIndex] = useState<number>(-1);
  const [isOpen, setIsOpen] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const dispatch = useDispatch();
  const { addToast } = useToast();

  const [errorFields, setErrorFields] = useState({
    procedureNumber: false,
    finalDate: false,
    date: false,
    procedureType: false,
    procedureOrigin: false,
  });

  useEffect(() => {
    (async () => {
      if (policeUnit) {
        const { unitId } = JSON.parse(policeUnit);
        setPoliceUnitId(unitId);

        dispatch(showLoading());
        const { data } = await api.get(
          `/ProcedureCustomer/GetAllByPolice?policeUnitid=${unitId}&startDate=2022-01-01&finishDate=2023-03-29`,
        );

        dispatch(hideLoading());
        setProcedureList(data.data);
      } else {
        addToast({
          type: 'warning',
          title: 'Selecione uma unidade de policia!',
          description: 'Para continuar selecione uma unidade de policia',
        });
        dispatch(hideLoading());
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  const validFields = () => {
    if (!date) {
      setErrorFields({ ...errorFields, date: true });
    }

    if (!finalDate) {
      setErrorFields({ ...errorFields, finalDate: true });
    }

    if (!procedureNumber) {
      setErrorFields({ ...errorFields, procedureNumber: true });
    }

    if (!procedureTypeId) {
      setErrorFields({ ...errorFields, procedureType: true });
    }

    if (!procedureOriginId) {
      setErrorFields({ ...errorFields, procedureOrigin: true });
    }
  };

  const createProcedure = async () => {
    try {
      console.log('aqui:');
      setErrorFields(errorFields);

      if (!date || !finalDate || !procedureNumber || !procedureOriginId || !policeUnitId) {
        validFields();
        addToast({
          type: 'warning',
          title: 'Preencha os campos!',
          description: 'Para continuar é necessario preencher todos os campos.',
        });

        return;
      }

      const bodyData = {
        guid: uuidv4(),
        expectedDate: date ? formatYmd(new Date(date)) : null,
        deadLineDate: finalDate ? formatYmd(new Date(finalDate)) : null,
        procedureNumber: procedureNumber,
        procedureCustomerTypeId: procedureTypeId,
        procedureCustomerSourceId: procedureOriginId,
        policeUnitId: policeUnitId,
        description: 'string',
        status: 'A',
        active: true,
      };

      dispatch(showLoading());

      await api.post('/ProcedureCustomer/add', bodyData);

      const { data } = await api.get(
        `/ProcedureCustomer/GetAllByPolice?policeUnitid=${policeUnitId}&startDate=2022-01-01&finishDate=2023-03-29`,
      );

      setFinalDate(null);
      setDate(null);
      setProcedureNumber('');
      setProcedureList(data.data);
      setProcedureTypeId(undefined);
      setProcedureOriginId(undefined);
      setIsOpen(false);
      addToast({
        type: 'success',
        title: 'Procedimento criado com sucesso!',
      });

      dispatch(hideLoading());
    } catch (error) {
      addToast({
        type: 'warning',
        title: 'Numero de procedimento cadastrado!',
        description: 'Já existe um procedimento na base de dados com esse numero ',
      });
      dispatch(hideLoading());
    }
  };

  const removeProcedure: any = async () => {
    try {
      if (true) {
        setIsEdit(false);
        setIsOpen(false);
        dispatch(showLoading());
        const body = [
          {
            op: 'replace',
            value: false,
            path: 'Active',
          },
        ];

        await api.patch(`/ProcedureCustomer/Update?id=${itemData.id}`, body);

        const { data } = await api.get(
          `/ProcedureCustomer/GetAllByPolice?policeUnitid=${policeUnitId}&startDate=2022-01-01&finishDate=2023-03-29`,
        );

        setItemData(null);
        setCurrentIndex(-1);
        dispatch(hideLoading());
        addToast({
          type: 'success',
          title: 'Procedimento removido com sucesso!',
        });
        setProcedureList(data.data);
      }
    } catch (error: any) {
      alert(`error: ${error.message}`);
      dispatch(hideLoading());
    }
  };

  const onOpenEditItem = () => {
    if (itemData) {
      setIsOpen(true);
      setIsEdit(true);

      setProcedureNumber(itemData.procedureNumber);
      setDate(itemData.expectedDate);
      setFinalDate(itemData.deadLineDate);
      setEditProcedureTypeName(itemData.procedureCustomerTypeName);
      setEditProcedureOriginName(itemData.procedureCustomerSourceName);
    }
  };

  const onCloseModal = () => {
    setFinalDate(null);
    setDate(null);
    setProcedureNumber('');
    setProcedureTypeId(undefined);
    setProcedureOriginId(undefined);
    setEditProcedureTypeName('');
    setEditProcedureOriginName('');
    setIsOpen(false);
    setIsEdit(false);
    setItemData(null);
    setCurrentIndex(-1);
  };

  const updateProcedureIem = async () => {
    try {
      dispatch(showLoading());

      const {
        data: { data: procedureItem },
      } = await api.get(`/ProcedureCustomer/Get?id=${itemData.id}`);

      const bodyData: IProcedure = {
        id: itemData.id,
        guid: procedureItem.guid,
        expectedDate: date ? formatYmd(new Date(date)) : procedureItem.expectedDate,
        deadLineDate: finalDate ? formatYmd(new Date(finalDate)) : procedureItem.deadLineDate,
        procedureNumber:
          itemData.procedureNumber === procedureItem.procedureNumber ? itemData.procedureNumber : procedureNumber,
        procedureCustomerTypeId: procedureTypeId ? procedureTypeId : procedureItem.procedureCustomerTypeId,
        procedureCustomerSourceId: procedureOriginId ? procedureOriginId : procedureItem.procedureCustomerSourceId,
        policeUnitId: policeUnitId,
        description: 'string',
        status: 'A',
        active: true,
      };

      await api.put('/ProcedureCustomer/Update', bodyData);

      const { data } = await api.get(
        `/ProcedureCustomer/GetAllByPolice?policeUnitid=${policeUnitId}&startDate=2022-01-01&finishDate=2023-03-29`,
      );

      setProcedureList(data.data);

      onCloseModal();
      dispatch(hideLoading());
      addToast({
        type: 'success',
        title: 'Atualização de procedimento',
        description: 'Procedimento atualizado com sucesso!',
      });
    } catch (err) {
      addToast({
        type: 'error',
        title: 'Atualização de procedimento',
        description: 'Numero de procedimento já existe.',
      });
      dispatch(hideLoading());
    }
  };

  //AREA HEARING============================================================================================================
  const [isEditHearing, setIsEditHearing] = useState(false);
  const [expectedDateValue, setExpectedDateValue] = useState<Date | null>(null);
  const [expectedTimeValue, setExpectedTimeValue] = useState<Date | null>(null);
  const [hearingTypeDropSelected, setHearingTypeDropSelected] = useState<number | null>();
  const [attendantDropSelected, setAttendantDropSelected] = useState<number | null>();
  const [openModalHearing, setOpenModalHearing] = useState(false);
  const [hearingList, setHearingList] = useState<Array<any>>([]);
  const authToken = localStorage.getItem('@Kenta:access_Token');
  //Edit Hearing
  const [itemDataHearing, setItemDataHearing] = useState<any | null>(null);
  const [editAttendantName, setEditAttendantName] = useState('');
  const [editHearingTypeName, setEditHearingTypeName] = useState('');
  const [testeOpenModal, setOpenModalTeste] = useState(false);
  const [reloadList, setReloadList] = useState(false);

  async function postHearing() {
    try {
      if (!hearingTypeDropSelected || !attendantDropSelected || !expectedDateValue || !expectedTimeValue) {
        addToast({
          type: 'warning',
          title: 'Oitiva',
          description: 'Preencha todos os campos para continuar',
        });
        return;
      }
      interface policeUnitInterface {
        unitId: number;
      }

      if (!policeUnit) {
        return;
      }

      const policeUnitObj: policeUnitInterface = JSON.parse(policeUnit);

      const bodyPost = {
        policeUnitId: policeUnitObj.unitId, //localStorage.getItem('@Kenta:unidade_selecionada'),
        procedureCustomerId: itemData.id,
        hearingTypeId: hearingTypeDropSelected,
        procedureCustomerAttendantId: attendantDropSelected,
        status: 'A',
        procedureNumber: itemData.procedureNumber,
        expectedDate: expectedDateValue + 'T' + expectedTimeValue,
        duration: 0,
        description: 'string',
        userId: 7, //localStorage.getItem('@Kenta:user'),
        exportCount: 0,
        isClassified: 0,
        active: true,
      };

      dispatch(showLoading());

      onCloseModalHearing();
      await api.post('/Hearing/Add', bodyPost);

      await getAllHearing();
      addToast({
        type: 'success',
        title: 'Oitiva criada com sucesso!',
      });

      dispatch(hideLoading());

      setOpenModalHearing(false);
      setReloadList(!reloadList);
    } catch (e) {
      console.log('error', e);
      addToast({
        type: 'warning',
        title: 'Ocorreu um erro!',
      });
      dispatch(hideLoading());
    }
  }

  async function putHearing() {
    try {
      dispatch(showLoading());

      const bodyPut = {
        id: itemDataHearing.id,
        guid: itemDataHearing.guid,
        foreignKey: itemDataHearing.foreignKey,
        policeUnitId: itemDataHearing.policeUnitId,
        procedureCustomerId: itemData.id,
        hearingTypeId: hearingTypeDropSelected ? hearingTypeDropSelected : itemDataHearing.hearingTypeId,
        procedureCustomerAttendantId: attendantDropSelected
          ? attendantDropSelected
          : itemDataHearing.procedureCustomerAttendantId,
        status: itemDataHearing.status,
        procedureNumber: itemData.procedureNumber,
        expectedDate: joinDateAndTime(itemDataHearing.expectedDate),
        duration: 0,
        description: 'string',
        userId: 7, //localStorage.getItem('@Kenta:user'),
        exportCount: 0,
        isClassified: 0,
        active: itemDataHearing.active,
      };

      onCloseModalHearing();

      await api.put('/Hearing/Update', bodyPut);

      await getAllHearing();
      addToast({
        type: 'success',
        title: 'Oitiva alterada com sucesso!',
      });

      dispatch(hideLoading());
    } catch {
      addToast({
        type: 'warning',
        title: 'Ocorreu um erro inesperado!',
      });
      dispatch(hideLoading());
    }
  }

  const onCloseModalHearing = () => {
    setExpectedDateValue(null);
    setExpectedTimeValue(null);
    setAttendantDropSelected(undefined);
    setHearingTypeDropSelected(undefined);
    setOpenModalHearing(false);
    setIsEditHearing(false);
    setOpenModalTeste(false);
  };

  function OpenModalEditHearing() {
    setIsEditHearing(true);
    setOpenModalHearing(true);

    if (itemDataHearing) {
      setEditAttendantName(itemDataHearing.attendantName);
      setEditHearingTypeName(itemDataHearing.hearingTypeName);

      const dateEdit = itemDataHearing.split('T');

      setExpectedDateValue(dateEdit[0]);
      setExpectedTimeValue(dateEdit[1]);
    }
  }

  function joinDateAndTime(expectedDateItem: any) {
    const dateTime = expectedDateItem.split('T');
    const date = dateTime[0];
    const time = dateTime[1];
    if (!expectedDateValue && !expectedTimeValue) {
      return expectedDateItem;
    } else if (!expectedDateValue) {
      return date + 'T' + expectedTimeValue;
    } else if (!expectedTimeValue) {
      return expectedDateValue + 'T' + time;
    } else if (expectedDateValue && expectedTimeValue) {
      return expectedDateValue + 'T' + expectedTimeValue;
    }
  }

  const getAllHearing = async () => {
    try {
      if (itemData) {
        dispatch(showLoading());
        const { data } = await api.get(`/Hearing/GetAllByProcedureCustomerId2?ProcedureCustomerId=${itemData.id}`);

        const listHearingActived: Array<any> = [];
        if (data.data) {
          data.data.forEach((element: { active: boolean }) => {
            if (element.active) listHearingActived.push(element);
          });
          setHearingList(listHearingActived);
          dispatch(hideLoading());
          return;
        }

        dispatch(hideLoading());
      }
    } catch (error) {
      dispatch(hideLoading());
    }
  };

  const onDeleteExcluirOitiva = async (hearingId: number) => {
    await HearingService.deleteHearing(authToken, hearingId);
    await getAllHearing();
    addToast({
      type: 'success',
      title: 'Oitiva excluida com sucesso!',
    });
  };

  //============================================================================================================

  return (
    <>
      <Container id='procedure_container_screen'>
        <ProcedureContainer id='procedure'>
          <ProcedureList
            setModalHearing={setOpenModalTeste}
            currentIndex={currentIndex}
            setCurrentIndex={setCurrentIndex}
            setItemData={setItemData}
            data={procedureList}
            title='Procedimentos'
            addButtonClick={() => setIsOpen(!isOpen)}
            deleteButtonClick={itemData ? removeProcedure : undefined}
          />
        </ProcedureContainer>
        <Hearing
          openModal={testeOpenModal}
          setItemData={setItemData}
          procedureItem={itemData}
          title='Oitivas'
          setIsEditHearing={setIsEditHearing}
          setOpenModal={setOpenModalTeste}
          addButtonClick={() => setOpenModalHearing(true)}
          reloadList={reloadList}
          setItemDataHearing={setItemDataHearing}
          getAllHearing={getAllHearing}
          hearingList={hearingList}
        />
        {!testeOpenModal && itemData && <RightSidebar onEditItem={onOpenEditItem} itemData={itemData} />}
        {testeOpenModal && (
          <ModalRightDetail
            isOpen={testeOpenModal}
            title='Detalhes da Oitiva'
            id='teste'
            setIsOpen={() => setOpenModalTeste(false)}>
            <HearingDetail
              procedureItem={itemData}
              onClickExcluir={() => onDeleteExcluirOitiva(itemDataHearing.id)}
              onClickEdit={() => OpenModalEditHearing()}
              hearing={itemDataHearing}
            />
          </ModalRightDetail>
        )}
      </Container>
      <ModalRight
        onClickAplicar={isEdit ? updateProcedureIem : createProcedure}
        setIsOpen={onCloseModal}
        title={isEdit ? 'Editar procedimento' : 'Criar procedimento'}
        isOpen={isOpen}
        onClickDelete={removeProcedure}
        isEdit={isEdit}>
        <ProcedureDetail
          itemData={itemData}
          editProcedureTypeName={editProcedureTypeName}
          editProcedureOriginName={editProcedureOriginName}
          procedureNumber={procedureNumber}
          errorFields={errorFields}
          setProcedureTypeId={setProcedureTypeId}
          setProcedureOriginId={setProcedureOriginId}
          setProcedureNumber={setProcedureNumber}
          setFinalDate={setFinalDate}
          setDate={setDate}
        />
      </ModalRight>
      <ModalRight
        setIsOpen={onCloseModalHearing}
        isOpen={openModalHearing}
        title={isEditHearing ? 'Editar Oitiva' : 'Criar Oitiva'}
        onClickAplicar={isEditHearing ? putHearing : postHearing}
        showIconDelete={itemDataHearing ? itemDataHearing.active : false}
        isEdit={isEditHearing}>
        <CreateEditHearing
          setDateSelected={setExpectedDateValue}
          setTimeSelected={setExpectedTimeValue}
          setAttendantDropSelected={setAttendantDropSelected}
          setHearingTypeDropSelected={setHearingTypeDropSelected}
          procedureCustomerId={itemData}
          //editando
          isEditHearing={isEditHearing}
          editAttendantName={editAttendantName}
          editHearingTypeName={editHearingTypeName}
          itemDataHearings={itemDataHearing}
        />
      </ModalRight>
    </>
  );
};
