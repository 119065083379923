import React, { useState } from 'react';

import { ScheduleItemSidebar } from '../ScheduleItemSidebar';
import { TitleSidebar } from '../TitleSidebar';
import { Container, DateContainer } from './styles';

export const ScheduledHearingSidebar: React.FC = () => {
  const [showHearing, setShowHearing] = useState(false);
  const [, setOpenModalHearing] = useState(false);

  
  return (
    <Container>
      <TitleSidebar
        quantity={2}
        titleSize={16}
        title='Oitivas agendadas'
        onShow={() => setShowHearing(!showHearing)}
        onAddSidebar={() => alert('adicionando')}
        show={showHearing}
      />
      {showHearing && (
        <DateContainer>
          <ScheduleItemSidebar
            onClick={() => setOpenModalHearing(true)}
            day='Hoje'
            hour='16h30'
            title='Amanda Vasconcelos'
            description='Vítima'
          />
          <ScheduleItemSidebar day='05/12' hour='10h' title='Melissa Coelho Menezes' description='Informante' />
        </DateContainer>
      )}
      {/* <ModalRightDetail
        isOpen={openModalHearing}
        setIsOpen={() => setOpenModalHearing(false)}
        title='Detalhes da Oitiva'
        onClickShowModal={onClickShowModal}>
        <HearingDetail hearing={{ id: '123123' }} onClickExcluir={() => setOpenModalHearing(false)} />
      </ModalRightDetail> */}
    </Container>
  );
};
