import api from '../../api';
import { IHearingIndex } from './interface';

export const markingService = {
  delete: async (id: number) => {
    try {
      await api.delete(`/HearingIndex/Delete?id=${id}`);
    } catch (error) {
      throw new Error('não foi possivel deletar essa marcação');
    }
  },
  update: async (item: IHearingIndex) => {
    try {
      await api.put(`/HearingIndex/Update`, item);
    } catch (error) {
      throw new Error('não foi possivel atualizar essa marcação');
    }
  },
};
