import React from 'react';
import { BrowserRouter } from 'react-router-dom';
import { Provider } from 'react-redux';
import { Router } from './routers';
import GlobalStyle from './global/styles';
import storeRedux from './store';
import AppProvider from './hooks';

function App() {
  return (
    <Provider store={storeRedux}>
      <AppProvider>
        <BrowserRouter>
          <GlobalStyle />
          <Router />
        </BrowserRouter>
      </AppProvider>
    </Provider>
  );
}

export default App;
