import styled from 'styled-components';

export const HearingListContainer = styled.div`
  width: 100% -48px;
  padding: 24px;
`;

export const Container = styled.div`
  height: 80px;
  width: 100% - 20px;
  display: flex;
  flex-direction: row;
  margin-bottom: 4px;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #ecf0f3;
  background-color: #ecf0f3;
  padding: 4px 16px 4px 4px;
  border-radius: 8px;

  &:hover {
    border: 2px solid #1d9bf0;
    opacity: 1;
    cursor: pointer;
  }
`;

export const ContainerDateText = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const TextArea = styled.div`
  width: 100%;
  //background-color: blue;
`;

export const ImgDiv = styled.div`
  padding-left: 3%;
  padding-right: 3%;
`;

export const Title = styled.div`
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 500;
  font-size: 14px;
  line-height: 125%;
  color: #333;
`;

export const Description = styled.div`
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #666;
  margin-top: 4px;
`;

export const InfoArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DateLabel = styled.div`
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
`;

// export const DateBlack = styled.div`
//   width: 100%;
//   height: 50%;
//   border-radius: 10%;
//   background-color: black;
//   color: white;
// `;

export const DateBlackDiv = styled.div`
  padding: 4px 8px;
  background: black;
  border-radius: 7px;
  margin: 25px;
`;

export const DateBlackSpan = styled.div`
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-style: normal;
  font-weight: 600;
  font-size: 12px;
  line-height: 15px;
  color: #fff;
`;
