import styled from 'styled-components';


interface IPicture {
  picture: string;
  size?: number;
}


export const Container = styled.div`
  background-color: #fff;
  width: 100%;
  height: 32px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  padding: 14px 0;
`;

export const ContainerLeft = styled.div`
  flex: 0.4;
  display: flex;
  justify-content: flex-start;
  align-items: center;
  padding-left: 8px;
  border-right: 1px solid #ccc;
`;

export const ContainerMiddle = styled.div`
  flex: 2;
  display: flex;
  flex-direction: row;
  justify-content: start;
  align-items: center;
  padding-left: 30px;
`;

export const ContainerUnidade = styled.div`
  flex: 2;
  display: flex;
  justify-content: flex-end;
  align-items: center;
  /* padding-right: 8px; */
`;

export const ContainerRight = styled.div`
  flex: 0.75;
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-right: 20px;
`;

export const PictureContainer =
  styled.div <
  IPicture >
  `
  width: ${(props) => (props.size ? props.size : 48)}px;
  height: ${(props) => (props.size ? props.size : 48)}px;
  border-radius: 50%;
  background: url(${(props) => props.picture}) no-repeat center;
  background-size: cover;
`;
