import api from '../../api';
export const transcription = {
  post: async (router: string, body: any) => {
    try {
      return await api.post(router, body);
    } catch (error: any) {
      throw new Error(error.message);
    }
  },
};
