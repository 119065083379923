import React, {  useState } from 'react';
import { ProcedureAttendantSidebar } from '../ProcedureAttendantSidebar';
import { ProcedureDataSidebar } from '../ProcedureDataSidebar';
import { ScheduledHearingSidebar } from '../ScheduledHearingSidebar';
import { TitleSidebar } from '../TitleSidebar';
import { Container, ItemsContainer, AttachmentEmpty } from './styles';

type IRightSidebar = {
  itemData: any | null,
  onEditItem?: () => void,
};

export const RightSidebar: React.FC<IRightSidebar> = ({ itemData, onEditItem }) => {
  const [showAttachment, setShowAttachment] = useState(false);

  return (
    <Container>
      <ItemsContainer>
        <ProcedureDataSidebar onEditItem={onEditItem} itemData={itemData} />
      </ItemsContainer>
      <ItemsContainer>
        <ScheduledHearingSidebar />
      </ItemsContainer>
      <ItemsContainer>
        <ProcedureAttendantSidebar />
      </ItemsContainer>
      <ItemsContainer>
        <TitleSidebar
          titleSize={16}
          title='Arquivos'
          onAddSidebar={() => alert('adicionando')}
          onShow={() => setShowAttachment(!showAttachment)}
          show={showAttachment}
        />
        {showAttachment && <AttachmentEmpty>Nenhum arquivo anexado</AttachmentEmpty>}
      </ItemsContainer>
    </Container>
  );
};
