/* eslint-disable @typescript-eslint/no-explicit-any */
import React from 'react';
import Modal from 'react-modal';
import { Toast } from '../../components/Toast';
import { Container, customStyles } from './styles';

interface IMessage {
  id: string;
  type?: 'success' | 'error' | 'warning';
  title: string;
  description?: string;
}

interface IToastList {
  messages: Array<IMessage>;
}

export const ToastList = ({ messages }: IToastList) => {
  return (
    <Modal ariaHideApp={false} isOpen={!!messages.length} style={{ ...customStyles }} contentLabel='Example Modal'>
      <Container id='toast_list'>
        {messages.map((item: any) => (
          <Toast id={item.id} key={item.id} title={item.title} type={item.type} description={item.description} />
        ))}
      </Container>
    </Modal>
  );
};
