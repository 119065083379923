import React, { useState } from 'react';
import showIcon from '../../../assets/hearingDetails/show.svg';
import deleteIcon from '../../../assets/hearingDetails/delete.svg';
import editIcon from '../../../assets/hearingDetails/edit.svg';
import hideIcon from '../../../assets/hearingDetails/hide.svg';
import plusIcon from '../../../assets/procedure/plus.svg';

import {
  Container,
  HeaderContainer,
  MarkingTime,
  MarkingTitle,
  MarkingDescription,
  HeaderTextContainer,
  ItemContainer,
  BottomMarkingTime,
  NewMarkingButton,
  ContainerDelete,
  IconsContainer,
} from './styles';
import { AttachmentEmpty } from '../../../components/RightSidebar/styles';
import { IMarkingType } from '../../../pages/app/hearingDetails';
import { useToast } from '../../../hooks/toast';

type IMarkingContent = {
  marking: IMarkingType,
  setOpenModal: (bool: boolean) => unknown,
  onDeleteMarking: (marking: any) => unknown,
  onEditMarking: (marking: any) => unknown,
  data: Array<any>,
  currentIndex: number,
  setCurrentIndex: (number: number) => unknown,
};

export const MarkingContent: React.FC<IMarkingContent> = ({
  marking,
  onDeleteMarking,
  onEditMarking,
  setOpenModal,
  data = [],
  currentIndex,
  setCurrentIndex,
}) => {
  const [height, ] = useState(1);
  const { addToast } = useToast();

  const formatVideoTime = (totalSeconds: number) => {
    const totalMinutes = Math.floor(totalSeconds / 60);

    const seconds = Math.floor(totalSeconds % 60);
    const minutes = totalMinutes % 60;
    const hours = Math.floor(totalMinutes / 60);

    return `${hours < 10 ? '0' + hours : hours}:${minutes < 10 ? '0' + minutes : minutes}:${
      seconds < 10 ? '0' + seconds : seconds
    }`;
  };

  // useEffect(() => {
  //   setHeight(innerHeight);
  // }, [innerHeight, innerWidth]);

  return (
    <>
      <Container height={height}>
        {data.length ? (
          data.map((item, index) => (
            <ItemContainer selected={index === currentIndex} key={item.id}>
              <HeaderContainer>
                <MarkingTime onClick={() => marking.toggleMarkingTime(item.offSet)}>
                  {formatVideoTime(item.offSet)}
                </MarkingTime>
                <HeaderTextContainer>
                  <MarkingTitle>{item.name}</MarkingTitle>

                  <IconsContainer>
                    {index === currentIndex && (
                      <ContainerDelete>
                        <img alt='' onClick={() => onEditMarking(item)} src={editIcon} />
                        <img alt='' onClick={() => onDeleteMarking(item)} src={deleteIcon} />
                      </ContainerDelete>
                    )}
                    <img
                    alt=''
                      onClick={() => setCurrentIndex(index === currentIndex ? -1 : index)}
                      src={index === currentIndex ? hideIcon : showIcon}
                    />
                  </IconsContainer>
                </HeaderTextContainer>
              </HeaderContainer>
              {index === currentIndex && <MarkingDescription>{item.subject}</MarkingDescription>}
            </ItemContainer>
          ))
        ) : (
          <center>
            <AttachmentEmpty>Nenhuma marcação criada</AttachmentEmpty>
          </center>
        )}
      </Container>
      <BottomMarkingTime>
        <NewMarkingButton
          onClick={() => {
            if (marking.playerState.playbackRate > 1) {
              addToast({
                type: 'warning',
                title: 'Impossível fazer marcação',
                description: 'Para fazer uma marcação é necessário o video está em velocidade 1x',
              });
            } else {
              if (marking.playerState.playing) {
                marking.toggleVideoPlay();
              }
              setOpenModal(true);
            }
          }}>
          <img alt='' src={plusIcon} /> <strong style={{ marginLeft: 10 }}>Nova marcação</strong>
        </NewMarkingButton>
      </BottomMarkingTime>
    </>
  );
};
