import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import login_logo from '../../../assets/login_logo.svg';
import microIcon from '../../../assets/login/micro.svg';
import videoIcon from '../../../assets/login/video.svg';
import googleplayIcon from '../../../assets/login/googleplay.svg';
import googleplayText from '../../../assets/login/googleplay_text.svg';
import appstoreIcon from '../../../assets/login/appstore.svg';
import appstoreText from '../../../assets/login/appstore_text.svg';
import {
  Container,
  Content,
  Button,
  Background,
  Form,
  Icon,
  ForgotPasswordButton,
  ContainerTextCenter,
  TopBar,
  IconsContainer,
  ContainerApp,
  ContainerAppInside,
  App,
} from './styles';
import { signIn } from '../../../services/auth';
import { useNavigate } from 'react-router-dom';
import { showLoading, hideLoading } from '../../../store/modules/login/actions';
import { InputAuth } from '../../../components/Login/Input';
import { useToast } from '../../../hooks/toast';

type typesInput = 'email' | 'password' | 'text' | undefined;

export const SignIn = () => {
  const [email, setEmail] = useState('');
  const [errorEmail, setErrorEmail] = useState(false);
  const [errorPassword, setErrorPassword] = useState(false);
  const [password, setPassword] = useState('');
  const [passwordType, setPasswordType] = useState<typesInput>('password');
  const [loading, setLoading] = useState(false);
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const { addToast } = useToast();

  useEffect(() => {
    localStorage.removeItem('@Kenta:user');
    localStorage.removeItem('@Kenta:access_Token');
    localStorage.removeItem('@Kenta:refresh_Token');
    localStorage.removeItem('@Kenta:expireIn');
  }, []);

  const onClickSignIn = async (e: any) => {
    e.preventDefault();
    try {
      if (email && password) {
        try {
          if (!/^[\w*0-9.-]+@[\w*0-9.]+\.[\w]+$/gi.test(email)) {
            addToast({
              type: 'warning',
              title: 'Email inválido',
              description: 'Para continuar insira um email válido!',
            });

            return;
          }

          setErrorEmail(false);
          setErrorPassword(false);
          setLoading(true);
          dispatch(showLoading());
          const response = await signIn({ email, password });

          if (!response.success) {
            if (
              (response.body &&
                response.body.response &&
                response.body.response.data &&
                response.body.response.data.message.includes('senha')) ||
              response.body.response.data.message.includes('usuário') ||
              response.body.response.data.message.includes('vinculado')
            ) {
              if (response.body.response.data.message.includes('vinculado')) {
                addToast({
                  type: 'error',
                  title: 'Usuário não vinculado!',
                  description: 'O usuário ainda não foi vinculado a nenhuma unidade de polícia!',
                });
              } else {
                addToast({ type: 'error', title: 'Usuário ou senha inválido' });
              }
            }
            setLoading(false);
            dispatch(hideLoading());
            return;
          }

          navigate('/dashboard/procedure');
          dispatch(hideLoading());
          setLoading(false);
        } catch (error) {
          console.log(error);
        }
      } else {
        if (!email) {
          setErrorEmail(true);
        }

        if (!password) {
          setErrorPassword(true);
        }

        addToast({
          type: 'error',
          title: 'Preencha todos os campos',
          description: 'Para fazer o login é necessário preencher todos os campos',
        });
      }
    } catch (error) {
      dispatch(hideLoading());
      setLoading(false);
    }
  };

  return (
    <Container>
      <Background>
        <ContainerTextCenter>
          <TopBar />
          <strong>Agilidade e segurança</strong>
          <span>na coleta de informações</span>
          <IconsContainer>
            <Icon>
              <img alt='' src={microIcon} />
            </Icon>
            <Icon>
              <img alt='' src={videoIcon} />
            </Icon>
          </IconsContainer>
        </ContainerTextCenter>
        <ContainerApp>
          <strong>Baixe o app</strong>
          <ContainerAppInside>
            <App
              onClick={() =>
                addToast({
                  type: 'success',
                  title: 'Redirecionamento',
                  description: 'Você será redirecionado para o Google Play!',
                })
              }>
              <img alt='' src={googleplayIcon} />
              <img alt='' src={googleplayText} />
            </App>
            <App
              onClick={() =>
                addToast({
                  type: 'success',
                  title: 'Redirecionamento',
                  description: 'Você será redirecionado para a App Store!',
                })
              }>
              <img alt='' src={appstoreIcon} />
              <img alt='' src={appstoreText} />
            </App>
          </ContainerAppInside>
        </ContainerApp>
      </Background>
      <Content>
        <img alt='' src={login_logo} />
        <Form onSubmit={onClickSignIn}>
          <InputAuth
            id='singIn_email'
            maxLength={200}
            error={errorEmail}
            label='Seu e-mail'
            type='text'
            disabled={loading}
            value={email}
            onChange={(email: string) => {
              setEmail(email);

              if (errorEmail) {
                setErrorEmail(false);
              }
            }}
          />
          <InputAuth
            id='singIn_password'
            maxLength={50}
            error={errorPassword}
            label='Sua senha'
            disabled={loading}
            onChange={(password: string) => {
              setPassword(password);

              if (errorPassword && password) {
                setErrorPassword(false);
              }
            }}
            value={password}
            setType={(type: typesInput) => setPasswordType(type)}
            icon={true}
            type={passwordType}
          />
          <Button disabled={loading} type='submit'>
            Entrar
          </Button>
        </Form>
        <ForgotPasswordButton>Esqueci a minha senha</ForgotPasswordButton>
      </Content>
    </Container>
  );
};
