import api from '../api';
import { ISignIn } from './interface';
import { SHA1 as sha1 } from 'crypto-js';
import { UserCustomerProfileService } from '../user_customer_profile';

export const signIn = async ({ email, password }: ISignIn) => {
  const body: ISignIn = {
    email,
    password: sha1(password).toString().toUpperCase(),
  };

  try {
    const { data } = await api.post('/UserCustomerAuthentication/CheckCredentials', body);

    localStorage.setItem('@Kenta:access_Token', data.data.tokenResponse.access_Token);
    localStorage.setItem('@Kenta:refresh_Token', data.data.tokenResponse.refresh_Token);

    // recuperar a primeira unidade de policia:
    if (data.data.userCustomer) {
      const response = await UserCustomerProfileService.getAllByUserCustomer(
        data.data.tokenResponse.access_Token,
        data.data.userCustomer.id,
      );

      if (response.data && response.data.length > 0)
        localStorage.setItem('@Kenta:unidade_selecionada', JSON.stringify(response.data[0]));
    }

    const dtNow = new Date();

    const user = { ...data.data.userCustomer, hash: data.data.userCustomerAccess.hash };

    localStorage.setItem('@Kenta:user', JSON.stringify(user));

    // Tempo para refresh do token
    localStorage.setItem('@Kenta:expireIn', String(new Date(dtNow.getTime() + 30 * 60 * 1000)));

    return {
      success: true,
      body: data,
    };
  } catch (error) {
    return {
      success: false,
      body: error,
    };
  }
};

export const logout = async (hash: string) => {
  const config = {
    headers: { Authorization: `Bearer ${localStorage.getItem('authToken')}` },
    params: {
      hash: hash,
    },
  };
  const response = await api.delete(`/UserCustomerAccess/DeleteUserCustomerAccess`, config);

  localStorage.clear();
  return response;
};
