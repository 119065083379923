import React from 'react';
import { ContainerSchedule, Scheduled } from './styles';

type IBadge = {
  text: string,
  type: string,
};

export const StatusBadge: React.FC<IBadge> = ({ text, type }) => {
  let colorBackground = '#30cd82';
  let colorText = '#fff';

  if (type === 'success') {
    colorBackground = '#30cd82';
    colorText = '#fff';
  } else if (type === 'warning') {
    colorBackground = '#f0ca68';
    colorText = '#000000';
  } else if (type === 'error') {
    colorBackground = '#eb5861';
    colorText = '#fff';
  }

  return (
    <ContainerSchedule color={colorBackground}>
      <Scheduled color={colorText}>{text}</Scheduled>
    </ContainerSchedule>
  );
};
