import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { TitleSidebar } from '../../components/TitleSidebar';
import { Container, ContainerMenu, DateContainer, HeadeContainer, MenuItem, Title } from './style';

export function MenuAdmin() {
  const [show, setShow] = useState(false);
  const navigate = useNavigate();
  const menuListItems = [
    { id: 1, name: 'Tipos de participantes', navigate: '/dashboard/administracao/tiposParticipantes' },
    { id: 2, name: 'Tipos de oitivas', navigate: '/dashboard/administracao/hearingType' },
    { id: 3, name: 'Origem do Procedimento', navigate: '/dashboard/administracao/procedureCustomerSource' },
  ];

  return (
    <Container>
      <HeadeContainer>
        <Title
          style={{
            paddingLeft: 20,
          }}>
          Administração
        </Title>
      </HeadeContainer>
      <ContainerMenu>
        <TitleSidebar titleSize={16} title='Cadastros' onShow={() => setShow(!show)} show={show} />
        {show && (
          <DateContainer>
            {menuListItems.map((item) => (
              <MenuItem key={item.id} onClick={() => navigate(item.navigate)}>
                {item.name}
              </MenuItem>
            ))}
          </DateContainer>
        )}
      </ContainerMenu>
    </Container>
  );
}
