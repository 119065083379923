import React from 'react';
import { Dropdown } from '../../components/Dropdown';
import { InputComponent } from '../../components/Details/InputTime';

import { Container, TimeContainer } from './styles';
import { TextAreaComponent } from '../../components/TextArea';

type IMarkingChildrenModal = {
  marking: any,
  toEditItem: any,
  isEdit: boolean,
  subject: string,
  setSubject: (e: string) => unknown,
  attendantList: Array<any>,
  setAttendantId: (id: any) => unknown,
};

export const MarkingChildrenModal: React.FC<IMarkingChildrenModal> = ({
  marking,
  toEditItem,
  isEdit,
  subject,
  setSubject,
  attendantList = [],
  setAttendantId,
}) => {
  const isRequiredList = {
    attendant: true,
    selectedTime: true,
    durationTime: true,
    description: true,
  };

  return (
    <Container>
      <TimeContainer>
        <InputComponent
          defaultValue={marking.playerState.currentTime}
          disabled={true}
          required={isRequiredList.selectedTime}
          placeholder='00:00'
          onChange={(e) => console.log(e)}
          label='Tempo'
        />
        <InputComponent
          defaultValue={marking.playerState.duration}
          placeholder={marking.playerState.duration}
          disabled={true}
          required={isRequiredList.durationTime}
          onChange={(e) => console.log(e)}
          label='Duração'
        />
      </TimeContainer>
      <Dropdown
        defaultValue={isEdit ? toEditItem.name : ''}
        options={attendantList}
        labelName='Participante'
        setSelected={setAttendantId}
        required={isRequiredList.attendant}
      />
      <TextAreaComponent style={{ marginTop: 10 }} onChange={setSubject} value={subject} label='Descrição' />
    </Container>
  );
};
