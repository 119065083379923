import api from '../api';

export const HearingService = {
  deleteHearing: async (authToken: string | null, hearingId: number) => {
    if (!authToken) {
      return {
        status: false,
        message: 'Não é possível inativar o registro sem o token correto.',
      };
    }

    if (!hearingId) {
      return {
        status: false,
        message: 'Não é possível inativar o registro sem o dado correto',
      };
    }

    try {
      const bodyDeleteHearing = [
        {
          op: 'replace',
          value: false,
          path: 'active',
        },
      ];
      const { data } = await api.patch(`Hearing/Update?id=${hearingId}`, bodyDeleteHearing);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: 'Houve um erro ao deletar o registro.',
      };
    }
  },
};
