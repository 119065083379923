import styled from 'styled-components';

export const Container = styled.div`
  height: 83vh;
  overflow-y: auto;
  overflow-x: hidden;
  padding: 8px;
  border-right: 1px solid #ecf0f3;

  /* width */
  ::-webkit-scrollbar {
    width: 4px;
  }

  /* Track */
  ::-webkit-scrollbar-track {
    background: #f1f1f1;
    margin-top: 16px;
  }

  /* Handle */
  ::-webkit-scrollbar-thumb {
    background: #1d9bf0;
    border-radius: 2px;
  }

  /* Handle on hover */
  ::-webkit-scrollbar-thumb:hover {
    background: #555;
  }
`;

export const Body = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  padding-top: 8px;
`;

export const Empty = styled.div`
  width: 100%;
  text-align: center;
  font-size: 14px;
  color: #7887a9;
  margin-top: 24px;
  font-family: 'Inter Tight', Arial, Helvetica, sans-serif;
`;
