import api from '../api';
import { ProcedureCustomerSourceInterface } from '../../pages/app/ProcedureCustomerSource';

export interface ProcedureCustomerSourceInterfaceResponse {
  data: ProcedureCustomerSourceInterface[];
  errors: string[];
  message: string;
  ok: boolean;
}

export const ProcedureCustomerSourceService = {
  getAllProcedureCustomerSource: async (customerId: number) => {
    try {
      const { data } = await api.get<ProcedureCustomerSourceInterfaceResponse>(
        `/ProcedureCustomerSource/GetAllByCustomerId?customerId=${customerId}`,
      );

      return {
        status: true,
        data: data,
        message: data.message,
      };
    } catch {
      return {
        status: false,
        data: null,
        message: 'Houve um erro ao consultar os registros.',
      };
    }
  },

  postProcedureCustomerSource: async (authToken: string | null, procedureCustomerSource: any) => {
    // if (validToken()) {
    //   return {
    //     status: false,
    //     message: 'Não é possível adicionar o registro sem o token correto.',
    //   };
    // }
    try {
      const body = {
        ...procedureCustomerSource,
      };
      const { data } = await api.post<any>(`/ProcedureCustomerSource/Add`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: 'Houve um erro ao cadastrar o registro.',
      };
    }
  },

  putProcedureCustomerSource: async (authToken: string | null, procedureCustomerSource: any) => {
    // if (!authToken) {
    //   return {
    //     status: false,
    //     message: 'Não é possível editar o registro sem o token correto.',
    //   };
    // }

    try {
      const body = {
        ...procedureCustomerSource,
      };
      const { data } = await api.put<ProcedureCustomerSourceInterface>(`/ProcedureCustomerSource/Update`, body);

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      return {
        status: false,
        message: 'Houve um erro ao atualizar o registro.',
      };
    }
  },

  deleteHearingType: async (authToken: string | null, hearingTypeId: number) => {
    // if (!authToken) {
    //   return {
    //     status: false,
    //     message: 'Não é possível editar o registro sem o token correto.',
    //   };
    // }
    try {
      const body = [
        {
          op: 'replace',
          value: false,
          path: 'active',
        },
      ];

      const { data } = await api.patch(`/HearingType/update?id=${hearingTypeId}`, body);

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      return {
        status: false,
        message: 'Houve um erro ao atualizar o registro.',
      };
    }
  },
};
