import React from 'react';

import {
  Container,
  HeaderContainer,
  TitleContainer,
  Title,
  //body
  ContainerBody,
  ContainerSearch,
  ContainerInput,
  ContainerList,
  //lista
  ListContainer,
  ItemContainer,
  ContainerCheckBoxAndText,
  CheckBoxDiv,
  TextArea,
} from './styles';

import { InputLabel } from '../../InputLabel';

import { CircleBadge } from '../../Badges/Circle';
import { AddButton } from '../../AddButton';
import { StatusBadge } from '../../Badges/Status';


type AdmProps = {
  title: string,
  //buttonAddPlus: <PlusIcon></PlusIcon>
  //AboutInputSearch
  InputSearchLabel: string,
  InputSearchEdit?: string,
  InputSearchOnChange: () => any,
  list: Array<any>,
  headerAddButtonOnClick: () => any,
  headerTrashButtonOnClick: () => any,
  onClick: (item: any) => void,
  setItemReturnClick: any,
  setOpenModal: any,
  setIsEdit: any,
};

export function AdminArea({
  title,
  InputSearchLabel,
  InputSearchEdit,
  InputSearchOnChange,
  list,
  headerAddButtonOnClick,
  headerTrashButtonOnClick,
  onClick,
}: AdmProps) {
  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <Title>
            {title}{' '}
            <CircleBadge color='#e98d0b' radius='1500px' text={list.length + ''} widthHeigt='25px' textColor='#fff' />
          </Title>
          <div>
            <AddButton onClick={headerAddButtonOnClick}></AddButton>
            {/* <img alt=''src={IconDelete} style={StyleDelete} onClick={props.headerTrashButtonOnClick} /> */}
          </div>
        </TitleContainer>
      </HeaderContainer>
      <ContainerBody>
        <ContainerSearch>
          <ContainerInput>
            <InputLabel
              label={InputSearchLabel}
              value={InputSearchEdit}
              onChange={() => {
                InputSearchOnChange()
              }}></InputLabel>
          </ContainerInput>
          {/* <ContainerOptions>
            <Dropdown labelName='Situação' options={[]} setSelected={null}></Dropdown>
          </ContainerOptions> */}
        </ContainerSearch>
        <ContainerList>
          <>
            <ListContainer>
              {list.map((item: any, index: number) => (
                <ItemContainer
                  key={item.id}
                  onClick={() => {
                    onClick(item);
                  }}>
                  <ContainerCheckBoxAndText>
                    <CheckBoxDiv>{/* <input type='checkbox'></input> */}</CheckBoxDiv>
                    <TextArea>{item.name || item.description}</TextArea>
                  </ContainerCheckBoxAndText>

                  {item.active ? (
                    <StatusBadge text='Ativo' type='success' />
                  ) : (
                    <StatusBadge text='Inativo' type='error' />
                  )}
                </ItemContainer>
              ))}
            </ListContainer>
          </>
        </ContainerList>
      </ContainerBody>
    </Container>
  );
}
