import React from 'react';
import videoIcon from '../../assets/procedure/video.svg';
import lowyerIcon from '../../assets/procedure/user.svg';
import { Container, IconContainer, AttendantContainer, Name, Role } from './styles';

export type IAttendantItem = {
  type: string,
  name: string,
  role: string,
};

export const AttendantItem: React.FC<IAttendantItem> = ({ type, name, role }) => {
  return (
    <Container>
      <IconContainer lowyer={type === 'lowyer'}>
        <img alt=''src={type === 'lowyer' ? lowyerIcon : videoIcon} />
      </IconContainer>
      <AttendantContainer>
        <Name>{name}</Name>
        <Role>{role}</Role>
      </AttendantContainer>
    </Container>
  );
};
