import React from 'react';
import {} from 'styled-components';
import { Container, Input, Label } from './style';

interface InputLabelProps {
  label?: string;
  value?: string;
  onChange: (e: string) => unknown;
  type?: string;
  disabled?: boolean;
}

export const InputLabel = ({ disabled = false, label, value, onChange, type = 'text' }: InputLabelProps) => {

  const onChangeValue = (valueChange: string) => {
    onChange(valueChange);
  };

  return (
    <Container>
      <Label>{label ? label : 'title:'}</Label>
      <Input
        value={value}
        placeholder={label ? label.replace(/:\*/gi, '') : ''}
        disabled={disabled}
        type={type}
        min='0'
        onChange={(e) => onChangeValue(e.target.value)}
      />
    </Container>
  );
};
