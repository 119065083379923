import styled from 'styled-components';

export const Container = styled.div`
  position: fixed;
  top: 5px;
  right: 5px;
`;

export const customStyles = {
  content: {
    border: 'none',
    backgroundColor: '#99999999',
    opacity: '0 !important',
    width: '0',
    height: 'auto',
    inset: 'auto',
    padding: 0,
    top: 0,
    left: 0,
    borderRadius: 0,
    // position: 'initial',
  },
  overlay: {
    backgroundColor: 'none',
    width: '0',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
