import React, { CSSProperties } from 'react';
import { Container, PlusIcon } from './styles';
import plus from '../../assets/procedure/plus.svg';

export type IProps = {
  onClick?: () => unknown,
  style?: CSSProperties,
  id?: string,
};

export const AddButton: React.FC<IProps> = ({ onClick, id }) => {
  return (
    <Container id={id} onClick={onClick}>
      <PlusIcon>
        <img alt='' src={plus} />
      </PlusIcon>
    </Container>
  );
};
