import styled from 'styled-components';

export const HeadeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background: #3e424e;
  padding: 0 25px;
  width: 315px;
  min-height: 80px;
  max-width: 360px;
`;

export const Title = styled.div`
  width: 200px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
`;
