import React, { useState, useEffect } from 'react';
import { Button } from '../Button';
import {
  Container,
  ContainerLeft,
  ContainerMiddle,
  ContainerRight,
  ContainerUnidade,
  PictureContainer,
} from './styles';
import logo from '../../assets/logo.png';
import lupa from '../../assets/svg/lupa.svg';
import notificacao from '../../assets/svg/notificacao.svg';
import ajuda from '../../assets/svg/ajuda.svg';
import CustomModal from '../Modal';
import ProfileModal from '../ProfileModal';
import { SelecionarUnidade } from '../../features/SelecionarUnidade';
import { UserCustomerProfile, UserCustomerProfileService } from '../../services/user_customer_profile';
import Avatar from 'react-avatar';
import { useNavigate } from 'react-router-dom';
import { storageBaseUrl } from '../../services/api';

export type HeaderUpMenuProps = {
  item: string,
};

interface User {
  id: number;
  picture?: string;
  name: string;
  customerId: number;
}

export const HeaderUpMenu = () => {
  const [isOpenSearch, setIsOpenSearch] = useState(false);
  const [isOpenSideModal, setIsOpenSideModal] = useState(false);
  const [activeItemMenu, setActiveItemMenu] = useState('Procedimentos');
  const [, setLoading] = useState(false);
  const [userCustomerProfiles, setUserCustomerProfiles] = useState<UserCustomerProfile[]>([]);
  const [user, setUser] = useState<User | null>();
  const [unit, setUnit] = useState<any | null>();
  const userStorage = localStorage.getItem('@Kenta:user');

  const navigate = useNavigate();
  const unitStorage = localStorage.getItem('@Kenta:unidade_selecionada');

  const searchProfiles = async () => {
    setLoading(true);
    const token = localStorage.getItem('@Kenta:access_Token');

    if (!userStorage) {
      return;
    }

    if (!token || !user || !user.customerId) {
      return;
    }

    const response = await UserCustomerProfileService.getAllByUserCustomer(token, user.id);

    if (!response.status) {
      console.log('erro', response.message);
    } else {
      if (!response.data) return;

      setUserCustomerProfiles(response.data);
    }
    setLoading(false);
  };

  useEffect(() => {
    searchProfiles();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [user]);

  useEffect(() => {
    if (userStorage) {
      setUser(JSON.parse(userStorage));
    }

    if (unitStorage) {
      setUnit(JSON.parse(unitStorage));
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <ContainerLeft>
        <img alt=''src={logo} width='100px' height='20px' />
      </ContainerLeft>
      <ContainerMiddle>
        <Button
          styles={{
            paddingLeft: 20,
            paddingRight: 20,
            paddingTop: 10,
            paddingBottom: 10,
          }}
          color={activeItemMenu !== 'Procedimentos' ? 'secondary' : 'primary'}
          onClick={() => {
            setActiveItemMenu('Procedimentos');
            navigate('/dashboard/procedure');
          }}>
          Procedimentos
        </Button>
        <Button
          color={activeItemMenu !== 'Administracao' ? 'secondary' : 'primary'}
          onClick={() => {
            setActiveItemMenu('Administracao');
            navigate('/dashboard/administracao');
          }}
          styles={{ marginLeft: '8px', marginRight: '8px' }}>
          Administração
        </Button>
        <Button
          color={activeItemMenu !== 'Relatorios' ? 'secondary' : 'primary'}
          onClick={() => {
            setActiveItemMenu('Relatorios');
            navigate('/dashboard/relatorio');
          }}
          styles={{ marginRight: '8px' }}>
          Relatórios
        </Button>
      </ContainerMiddle>
      <ContainerUnidade>
        {userCustomerProfiles && userCustomerProfiles.length > 0 ? (
          <SelecionarUnidade unidades={userCustomerProfiles} />
        ) : null}
      </ContainerUnidade>
      <ContainerRight>
        <img alt=''src={lupa} onClick={() => setIsOpenSearch(!isOpenSearch)} style={{ cursor: 'pointer' }} />
        <img alt=''src={notificacao} style={{ cursor: 'pointer' }} />
        <img alt=''src={ajuda} style={{ cursor: 'pointer' }} />
        {userCustomerProfiles && userCustomerProfiles.length > 0 ? (
          <>
            {user && unit && unit.containerName && user.picture ? (
              <PictureContainer
                onClick={() => setIsOpenSideModal(true)}
                picture={`${storageBaseUrl}/${unit.containerName}/${user.picture}`}
              />
            ) : (
              <Avatar
                name={user ? user.name : 'user test'}
                size='48'
                round={true}
                onClick={() => setIsOpenSideModal(true)}
              />
            )}
            <ProfileModal
              isOpen={isOpenSideModal}
              onClose={() => setIsOpenSideModal(false)}
              profile={userCustomerProfiles[0].username}
              userName={user && user.name ? user.name : 'user test'}
            />
          </>
        ) : null}
      </ContainerRight>
      <CustomModal isOpen={isOpenSearch} />
      {/* <ModalRight isOpen={isOpenSideModal}>
        <Oitiva />
      </ModalRight> */}
    </Container>
  );
};
