import styled from 'styled-components';

export const ContainerGeral = styled.div`
  position: absolute;
  background: #fff;
  opacity: 0.4;
  width: 100%;
  height: 100vh;
`;

export const Container = styled.div`
  position: absolute;
  align-items: center;
  left: 50%;
  padding: 8px 16px;
  transform: translateX(-50%);
  background: #fbe4b6;
  border: 1px solid #f2a60d;
  display: flex;
  margin-top: 10px;
  border-radius: 8px;
  transition: 0.3s;

  span {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    font-size: 14px;
    font-weight: 600;
  }

  div {
    margin-left: 10px;
  }
`;
