import { configureStore } from '@reduxjs/toolkit';
import attachmentReducer from './modules/attachment/reducer';
import userReducer from './modules/user/reducer';
import profileReducer from './modules/profile/reducer';
import loadingReducer from './modules/login/reducer';

export default configureStore({
  reducer: {
    attachment: attachmentReducer,
    user: userReducer,
    profile: profileReducer,
    loading: loadingReducer,
  },
});
