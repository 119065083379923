import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #ecf0f3;
  padding: 4px 16px 4px 4px;
  border-radius: 8px;

  &:hover {
    border: 2px solid #1d9bf0;
    opacity: 1;

    .icons {
      opacity: 1;
      display: block;
    }
  }
`;

export const ContainerDateText = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const DateArea = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 52px;
  background: #d1dbe3;
  border-radius: 4px;
  margin-right: 12px;
`;

export const Day = styled.div`
  font-size: 12px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: #000;
`;

export const Hour = styled.div`
  font-size: 12px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #666;
  margin-top: 4px;
`;
export const TextArea = styled.div``;

export const Title = styled.div`
  font-size: 12px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 600;
  color: #000;
`;
export const Description = styled.div`
  font-size: 12px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  color: #666;
  margin-top: 4px;
`;

export const IconArea = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  opacity: 0;
  display: none;

  img {
    cursor: pointer;
  }

  img + img {
    margin-left: 10px;
  }
`;
