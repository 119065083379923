import React, { useState, useEffect } from 'react';
import { AdminArea } from '../../../components/Body/AdminArea';
import ModalRight from '../../../components/ModalRight';
import { CreateEditProcedureCustomerSource } from './CreateEdit';
import { ProcedureCustomerSourceService } from '../../../services/procedureCustomerSource';
import { stringFormat } from '../../../utils/stringFormat';

//Toast
import { hideLoading, showLoading } from '../../../store/modules/login/actions';
import { useDispatch } from 'react-redux';
import { useToast } from '../../../hooks/toast';

export interface ProcedureCustomerSourceInterface {
  active: boolean;
  customerId: number;
  id: number;
  name: string;
}

export function ProcedureCustomerSource() {
  const [listProcedureCustomerSource, setListProcedureCustomerSource] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [nameProcedureCustomerSource, setNameProcedureCustomerSource] = useState<string>('');
  const [element, setElement] = useState<ProcedureCustomerSourceInterface>();
  const user = localStorage.getItem('@Kenta:user');

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let customerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  useEffect(() => {
    onLoadList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  async function onLoadList() {
    const response = await ProcedureCustomerSourceService.getAllProcedureCustomerSource(customerId);
    if (response) {
      if (response.status) {
        setListProcedureCustomerSource(response.data?.data);
      } else {
        addToast({
          type: 'warning',
          title: response.message,
        });
      }
    }
    return [];
  }

  function onChangeInputSearch() {
    console.log('onChangeInputSearch');
  }

  function clear() {
    setIsEdit(false);
    setNameProcedureCustomerSource('');
    setElement(undefined);
    setOpenModal(false);
  }

  function openModalClearAdd() {
    clear();
    setOpenModal(true);
  }

  function headerTrashButtonOnClick() {
    alert('clicou headerTrashButtonOnClick');
  }

  function onClickProcedureCustomerSource(item: ProcedureCustomerSourceInterface) {
    setIsEdit(true);
    setNameProcedureCustomerSource(item.name);
    setElement(item);
    setOpenModal(true);
  }

  function createBody() {
    return {
      id: isEdit ? element?.id : 0,
      customerId: customerId,
      name: nameProcedureCustomerSource,
      active: isEdit ? element?.active : true,
    };
  }

  async function postProcedureCustomerSource() {
    if (!nameProcedureCustomerSource || stringFormat.isEmptyOrSpaces(nameProcedureCustomerSource)) {
      addToast({
        type: 'warning',
        title: 'Preencha o campo!',
      });
      return;
    }

    const existName = stringFormat.verifyExistNameInArray(listProcedureCustomerSource, nameProcedureCustomerSource);

    if (existName) {
      addToast({
        type: 'warning',
        title: 'Esse nome já existe!',
      });
      return;
    }

    dispatch(showLoading());

    setOpenModal(false);
    const resultPost = await ProcedureCustomerSourceService.postProcedureCustomerSource('', createBody());
    if (resultPost) {
      addToast({
        type: 'success',
        title: 'Origem do Procedimento criada com sucesso!',
      });
      clear();
      await onLoadList();
    } else {
      addToast({
        type: 'warning',
        title: 'Falha ao criar a Origem do Procedimento!',
      });
    }

    dispatch(hideLoading());
  }

  async function putProcedureCustomerSource() {
    if (!nameProcedureCustomerSource || stringFormat.isEmptyOrSpaces(nameProcedureCustomerSource)) {
      addToast({
        type: 'warning',
        title: 'Preencha o campo!',
      });
      return;
    }

    const existName = stringFormat.verifyExistNameInArray(
      listProcedureCustomerSource,
      nameProcedureCustomerSource,
      element?.id,
    );

    if (existName) {
      addToast({
        type: 'warning',
        title: 'Esse nome já existe!',
      });
      return;
    }

    dispatch(showLoading());

    setOpenModal(false);
    const resultPut = await ProcedureCustomerSourceService.putProcedureCustomerSource('', createBody());
    if (resultPut) {
      addToast({
        type: 'success',
        title: 'Origem do Procedimento editada com sucesso!',
      });
      clear();
      await onLoadList();
    } else {
      addToast({
        type: 'warning',
        title: 'Falha ao criar a Origem do Procedimento!',
      });
    }
    dispatch(hideLoading());
  }

  async function deleteProcedureCustomerSource() {
    alert('click deleteProcedureCustomerSource');
  }

  return (
    <>
      <AdminArea
        title={'Origem do Procedimento'}
        InputSearchLabel='Buscar por:'
        InputSearchOnChange={onChangeInputSearch}
        list={listProcedureCustomerSource}
        headerAddButtonOnClick={() => openModalClearAdd()}
        headerTrashButtonOnClick={headerTrashButtonOnClick}
        onClick={(item: any) => onClickProcedureCustomerSource(item)}
        setItemReturnClick={setElement}
        setOpenModal={setOpenModal}
        setIsEdit={setIsEdit}
      />
      <ModalRight
        isOpen={openModal}
        setIsOpen={() => clear()}
        isEdit={isEdit}
        title={isEdit ? 'Editando Origem do Procedimento' : 'Criar Origem do Procedimento'}
        onClickAplicar={isEdit ? putProcedureCustomerSource : postProcedureCustomerSource}
        showIconDelete={element?.active}
        onClickDelete={deleteProcedureCustomerSource}>
        <CreateEditProcedureCustomerSource
          onChangeName={setNameProcedureCustomerSource}
          valueEdit={nameProcedureCustomerSource}
        />
      </ModalRight>
    </>
  );
}
