import React, { useState } from 'react';
import { ProcedureDataSidebar } from '../../../components/ProcedureDataSidebar';
import { AttachmentEmpty } from '../../../components/RightSidebar/styles';
import { TitleSidebar } from '../../../components/TitleSidebar';
import { Container, ItemsContainer } from './styles';

type IHearingInformation = {
  procedureItem: any,
};

const HearingInformation: React.FC<IHearingInformation> = ({ procedureItem }) => {
  const [showAttachment, setShowAttachment] = useState(false);
  return (
    <Container>
      <ItemsContainer>
        {procedureItem ? <ProcedureDataSidebar itemData={procedureItem} /> : 'carregando...'}
      </ItemsContainer>
      <ItemsContainer>
        <TitleSidebar
          titleSize={16}
          title='Arquivos'
          onAddSidebar={() => alert('adicionando')}
          onShow={() => setShowAttachment(!showAttachment)}
          show={showAttachment}
        />
        {showAttachment && <AttachmentEmpty>Nenhum arquivo anexado</AttachmentEmpty>}
      </ItemsContainer>
    </Container>
  );
};

export default HearingInformation;
