import React from 'react';
import { FiEyeOff, FiEye } from 'react-icons/fi';

import { Container, Label, InputText, ContainerInput, ContainerIcon } from './styles';

type IInput = {
  id?: string,
  label: string,
  value: string,
  icon?: boolean,
  maxLength?: number,
  type?: 'text' | 'password' | 'email',
  error: boolean,
  setType?: (e: any) => unknown,
  onChange: (e: string) => unknown,
  disabled?: boolean,
};

export const InputAuth: React.FC<IInput> = ({
  id,
  label,
  maxLength,
  onChange,
  value,
  disabled = false,
  error = false,
  type = 'text',
  setType,
  icon = false,
}) => {
  return (
    <Container>
      <Label>{label}</Label>
      <ContainerInput notError={!!value} error={error}>
        <InputText
          id={id}
          maxLength={maxLength}
          autoComplete='on'
          autoCapitalize='off'
          disabled={disabled}
          type={type}
          value={value}
          onChange={(e) => onChange(e.target.value)}
        />
        {icon && setType && (
          <ContainerIcon onClick={() => setType(type === 'text' ? 'password' : 'text')} type='button'>
            {type === 'text' ? <FiEyeOff color='#7887A9' size={20} /> : <FiEye color='#7887A9' size={20} />}
          </ContainerIcon>
        )}
      </ContainerInput>
    </Container>
  );
};
