import api from '../api';

export interface TipoParticipanteData {
  active: boolean;
  customerId: number;
  id: number;
  name: string;
}

export interface TipoParticipanteResponse {
  data: TipoParticipanteData[];
  errors: string[];
  message: string;
  ok: boolean;
}

export const ParticipantesService = {
  getAllParticipantes: async (authToken: string | null) => {
    if (!authToken) {
      return {
        status: false,
        message: 'Não é possível inativar o registro sem o token correto.',
      };
    }

    try {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` },
      };
      const { data } = await api.get<TipoParticipanteResponse>(
        `/AttendantRole/GetAllByCustomerId?customerId=${1}`,
        config,
      );

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      return {
        status: false,
        message: 'Houve um erro ao deletar o registro.',
      };
    }
  },
  createParticipante: async (authToken: string | null, participant: any) => {
    if (!authToken) {
      return {
        status: false,
        message: 'Não é possível inativar o registro sem o token correto.',
      };
    }

    try {
      const body = {
        ...participant,
      };
      const { data } = await api.post<any>(`/AttendantRole/add`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: 'Houve um erro ao cadastrar o registro.',
      };
    }
  },
  editParticipante: async (authToken: string | null, participant: any) => {
    if (!authToken) {
      return {
        status: false,
        message: 'Não é possível inativar o registro sem o token correto.',
      };
    }

    try {
      const body = {
        ...participant,
      };
      const { data } = await api.put<any>(`/AttendantRole/update`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: 'Houve um erro ao cadastrar o registro.',
      };
    }
  },
  ativarInativarTipoParticipante: async (authToken: string | null, id: number, valueActive: any) => {
    if (!authToken) {
      return {
        status: false,
        message: 'Não é possível inativar o registro sem o token correto.',
      };
    }
    console.log('value active:', valueActive);

    try {
      const body = [
        {
          op: 'replace',
          value: !valueActive,
          path: 'active',
        },
      ];
      const { data } = await api.patch<any>(`/AttendantRole/update?id=${id}`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: 'Houve um erro ao cadastrar o registro.',
      };
    }
  },
};
