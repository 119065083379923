import React, { useState, useCallback } from 'react';
import { Container, Hr, Line, Label, VideoContainer, Icon } from './style';

//imagens
import CameraIcon from '../../../assets/svg/CameraIcon.svg';
import EditIcon from '../../../assets/svg/EditIcon.svg';
import EyeIcon from '../../../assets/svg/EyeIcon.svg';
import FileIcon from '../../../assets/svg/FileIcon.svg';
import PencilIcon from '../../../assets/svg/PencilIcon.svg';
import TrashIcon from '../../../assets/svg/TrashIcon.svg';
import { ModalConfirmation } from '../../ModalConfirmation';
import { useNavigate } from 'react-router-dom';

type HearingDetailsProps = {
  //colocar o tipo correto depois
  hearing: any,
  procedureItem: any,
  onClickExcluir: () => unknown,
  onClickEdit: () => unknown,
};



export const HearingDetail = ({
  hearing,
  onClickExcluir,
  onClickEdit,
  procedureItem,
}: HearingDetailsProps): JSX.Element => {
  const token = localStorage.getItem('@Kenta:access_Token');
  const [showModalConfirmation, setShowModalConfirmation] = useState(false);
  const navigate = useNavigate();

  const deleteHearing = async () => {
    if (token && hearing) {
      setShowModalConfirmation(true);
    }
  };

  const hearingDetails = useCallback(async () => {
    if (hearing) {
      navigate(`hearingDetails?hearingId=${hearing.id}&procedureId=${procedureItem.id}`);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hearing, navigate]);

  return (
    <Container>
      <VideoContainer>
        <video width='100%' height='200' controls>
          <source
            src={'http://commondatastorage.googleapis.com/gtv-videos-bucket/sample/BigBuckBunny.mp4'}
            type='video/mp4'
          />
          Desculpe, seu navegador não tem suporte o player de vídeo.
        </video>
      </VideoContainer>
      <Line>
        <Icon>
          <img alt=''src={EyeIcon} />
        </Icon>
        <Label onClick={hearingDetails}>Visualização Completa</Label>
      </Line>
      <Line>
        <Icon>
          <img alt=''src={EditIcon} />
        </Icon>
        <Label onClick={() => alert('Marcações!')}>Marcações</Label>
      </Line>
      <Line>
        <Icon>
          <img alt=''src={FileIcon} />
        </Icon>
        <Label onClick={() => alert('Transcrição!')}>Transcrição</Label>
      </Line>
      <Line>
        <Icon>
          <img alt=''src={EyeIcon} />
        </Icon>
        <Label onClick={() => alert('Arquivos!')}>Arquivos (sem imagem para exportar)</Label>
      </Line>
      <Hr />
      <Line>
        <Icon>
          <img alt=''src={CameraIcon} />
        </Icon>
        <Label onClick={() => alert('Gravar nova oitiva do participante!')}>Gravar nova oitiva do participante</Label>
      </Line>
      <Hr />
      <Line>
        <Icon>
          <img alt=''src={PencilIcon} />
        </Icon>
        {/* <Label onClick={() => alert('Editar informações!')}>Editar informações</Label> */}
        <Label onClick={onClickEdit}>Editar informações</Label>
      </Line>
      <Hr />
      <Line>
        <Icon>
          <img alt=''src={TrashIcon} />
        </Icon>
        <Label onClick={() => deleteHearing()}>Excluir</Label>
        <ModalConfirmation
          onClose={() => {
            setShowModalConfirmation(false);
          }}
          title='Excluir oitiva'
          titleBody='Deseja excluir a oitiva?'
          subtitleBody='Esta ação não poderá ser desfeita'
          labelButton='Excluir'
          onClickButton={() => {
            onClickExcluir();
            setShowModalConfirmation(false);
          }}
          isOpen={showModalConfirmation}
        />
      </Line>
    </Container>
  );
};
