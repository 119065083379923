/* eslint-disable @typescript-eslint/no-empty-function */
import React, { useState } from 'react';
import { DropDownContainer, DropDownHeader, DropDownList, DropDownListContainer, Label, ListItem } from './style';
import { CircleBadge } from '../Badges/Circle';

export interface IDropDown {
  value: string;
  key: number;
}
export interface iDropdown {
  labelName: string;
  options: IDropDown[];
  defaultValue?: string;
  setSelected: any;
  editProcedure?: any;
  required?: boolean;
}

export const Dropdown: React.FC<iDropdown> = ({
  labelName,
  options,
  setSelected,
  editProcedure,
  defaultValue,
  required = false,
}) => {
  const [isOpen, setIsOpen] = useState(false);
  const [, setError] = useState(false);
  const [selectedOption, setSelectedOption] = useState(editProcedure ? editProcedure : defaultValue || 'Selecione');

  const toggling = () => {
    if (isOpen && selectedOption === 'Selecione') {
      setError(true);
    } else {
      setError(false);
    }
    setIsOpen(!isOpen);
  };

  const onOptionClicked = (value: string, option?: any) => () => {
    setSelectedOption(value);
    setIsOpen(false);

    if (option) {
      setSelected(option.id);
    }
  };

  return (
    <DropDownContainer>
      <Label>{labelName}</Label> <CircleBadge color='#e98d0b' radius='50px' widthHeigt='10px'></CircleBadge>
      <DropDownHeader onClick={toggling}>{selectedOption || '---'}</DropDownHeader>
      {isOpen && (
        <DropDownListContainer>
          <DropDownList>
            {options.map((item: any) => (
              <ListItem onClick={onOptionClicked(item.name || item, item)} key={item.id}>
                {item.name || item}
              </ListItem>
            ))}
          </DropDownList>
        </DropDownListContainer>
      )}
    </DropDownContainer>
  );
};
