import React from 'react';
import { Route, Routes } from 'react-router-dom';
import { MenuAdmin } from '../../../features/MenuAdmin';
import { TiposParticipantes } from '../TiposParticipantes';
import { HearingType } from '../HearingType';
import { ProcedureCustomerSource } from '../ProcedureCustomerSource';

import { Container, Body } from './styles';

export const Administration: React.FC = () => {
  return (
    <Container>
      <MenuAdmin />
      <Body>
        <Routes>
          <Route path='/tiposParticipantes' element={<TiposParticipantes />} />
          <Route path='/hearingType' element={<HearingType />} />
          <Route path='/ProcedureCustomerSource' element={<ProcedureCustomerSource />} />
        </Routes>
      </Body>
    </Container>
  );
};
