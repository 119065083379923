import styled from 'styled-components';

interface IProps {
  size?: number;
}

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

export const Title = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 700;
  color: #000;
  font-size: ${({ size }: IProps) => (size ? size : 18)}px;
  margin-right: 8px;
`;

export const EditContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border-radius: 50%;
  border: 2px solid #1d9bf0;
  cursor: pointer;
`;

export const ShowContainer = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  border: none;
  cursor: pointer;
  margin-left: 10px;
`;

export const RightContainerSidebar = styled.div`
  display: flex;
  flex-direction: row;
`;
