import React from 'react';
import close from '../../assets/svg/close.svg';
import {
  Container,
  Content,
  Button,
  ContainerBody,
  ContainerFooter,
  ContainerTitle,
  styleDelete,
  Title,
} from './style';
import IconDelete from '../../assets/svg/IconDelete.svg';

interface ModalType {
  title?: string;
  children: JSX.Element;
  isOpen: boolean;
  isEdit?: boolean;
  setIsOpen?: () => void;
  onClickDelete?: () => void;
  showIconDelete?: boolean;
  onClickAplicar?: (props: any) => void;
}

export default function ModalRight(props: ModalType) {
  return props.isOpen ? (
    <Container>
      <Content>
        <ContainerTitle>
          <Title>{props.title ? props.title : 'Titulo da página'}</Title>
          <img alt=''src={close} onClick={props.setIsOpen} />
        </ContainerTitle>
        <ContainerBody>{props.children}</ContainerBody>
        <ContainerFooter>
          <Button onClick={props.onClickAplicar}>
            <span>Aplicar</span>
          </Button>
          {props.isEdit && (props.showIconDelete || props.showIconDelete === undefined) && (
            <img alt=''src={IconDelete} style={styleDelete} onClick={props.onClickDelete} />
          )}
        </ContainerFooter>
      </Content>
    </Container>
  ) : (
    <div />
  );
}
