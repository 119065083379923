import React from 'react';
import playIcon from '../../assets/hearingDetails/play.svg';
import pauseIcon from '../../assets/hearingDetails/pause.svg';
import backIconDisabled from '../../assets/hearingDetails/backIcon_disabled.svg';
import backIcon from '../../assets/hearingDetails/back.svg';
import advanceIcon from '../../assets/hearingDetails/advance.svg';
import fullscreenIcon from '../../assets/hearingDetails/fullscreen.svg';
import volumeIcon from '../../assets/hearingDetails/volume.svg';
import volumeMuteIcon from '../../assets/hearingDetails/volume-mute.svg';

import {
  Container,
  LeftButton,
  CenterButton,
  RightButton,
  TimeButton,
  PlayButton,
  Time,
  ButtonIcon,
  ButtonIconVolume,
  PlaybackRate,
  PlaybackRateMenu,
  PlaybackRateContainer,
  PlayerContainer,
} from './styles';
import PercentageBar from '../../components/PercentageBar';

type IPlayerControl = {
  playerState: {
    playing: boolean,
    progress: number,
    duration: string | number,
    currentTime: string | number,
    volume: number,
    playbackRate: number,
  },
  toggleVideoPlay: () => unknown,
  toggleChangeProgress: (progress: number) => unknown,
  toggleFullscreen: () => unknown,
  toggleFiveSeconds: (type: 'advance' | 'back') => unknown,
  toggleVolume: (volume: number) => unknown,
  toggleRate: (rate: number) => unknown,
};

const PlayerControl: React.FC<IPlayerControl> = ({
  playerState,
  toggleVideoPlay,
  toggleFullscreen,
  toggleChangeProgress,
  toggleFiveSeconds,
  toggleVolume,
  toggleRate,
}) => {
  const disabledBack = playerState.currentTime <= 0 || playerState.currentTime === '00:00:00';
  const disabledAdvance = playerState.currentTime >= playerState.duration;

  const playbackRateList = [
    { id: 1, value: 0.25, label: '0.25' },
    { id: 2, value: 0.5, label: '0.5' },
    { id: 3, value: 0.75, label: '0.75' },
    { id: 4, value: 1.0, label: 'Normal' },
    { id: 5, value: 1.25, label: '1.25' },
    { id: 6, value: 1.5, label: '1.5' },
    { id: 7, value: 1.75, label: '1.75' },
    { id: 8, value: 2.0, label: '2.0' },
  ];

  return (
    <PlayerContainer>
      <PercentageBar changePercentage={toggleChangeProgress} percent={playerState.progress} />
      <Container>
        <LeftButton>
          <ButtonIconVolume>
            <img alt=''src={playerState.volume ? volumeIcon : volumeMuteIcon}  />
            <input
              id='volume'
              min='0'
              max='100'
              value={Number(playerState.volume * 100)}
              onChange={(e) => toggleVolume(+e.target.value / 100)}
              type='range'
            />
          </ButtonIconVolume>
        </LeftButton>
        <CenterButton>
          <TimeButton onClick={() => toggleFiveSeconds('back')} disabled={disabledBack}>
            <img alt='' src={disabledBack ? backIconDisabled : backIcon} />
          </TimeButton>
          <PlayButton playing={playerState.playing} onClick={toggleVideoPlay}>
            <img alt='' src={playerState.playing ? pauseIcon : playIcon} />
          </PlayButton>
          <TimeButton disabled={disabledAdvance} onClick={() => toggleFiveSeconds('advance')}>
            <img alt='' src={advanceIcon} />
          </TimeButton>
          <Time>
            {playerState.currentTime < 10
              ? `0${playerState.currentTime.toString().replace('.', ':')}`
              : playerState.currentTime}{' '}
            /{' '}
            {playerState.duration < 10 ? `0${playerState.duration.toString().replace('.', ':')}` : playerState.duration}
          </Time>
        </CenterButton>
        <RightButton>
          <PlaybackRateContainer>
            <PlaybackRate>{playerState.playbackRate}X</PlaybackRate>
            <PlaybackRateMenu id='playbackList'>
              {playbackRateList.map((item) => (
                <strong onClick={() => toggleRate(item.value)} key={item.id}>
                  {item.label}
                </strong>
              ))}
            </PlaybackRateMenu>
          </PlaybackRateContainer>
          <ButtonIcon onClick={toggleFullscreen}>
            <img alt='' src={fullscreenIcon} />
          </ButtonIcon>
        </RightButton>
      </Container>
    </PlayerContainer>
  );
};

export default PlayerControl;
