import React from 'react';
import { Button } from '../Button';
import {
  Container,
  ContainerBody,
  ContainerFooter,
  ContainerHeader,
  customStyles,
  styleImage,
  SubtitleBody,
  Title,
  TitleBody,
} from './style';
import Modal from 'react-modal';
import close from '../../assets/svg/close.svg';

type ModalConfirmationProps = {
  isOpen: boolean,
  onClose: () => void,
  title: string,
  titleBody: string,
  subtitleBody: string,
  labelButton: string,
  onClickButton: () => void,
};

export function ModalConfirmation(props: ModalConfirmationProps) {
  return (
    <Modal ariaHideApp={false} isOpen={props.isOpen} style={{ ...customStyles }} contentLabel='Example Modal'>
      <Container>
        <ContainerHeader>
          <img alt=''src={close} style={{ ...styleImage, position: 'absolute' }} onClick={props.onClose} />
          <Title>{props.title ? props.title : 'Titulo da página'}</Title>
        </ContainerHeader>
        <ContainerBody>
          <TitleBody>{props.titleBody}</TitleBody>
          <SubtitleBody>{props.subtitleBody}</SubtitleBody>
        </ContainerBody>
        <ContainerFooter>
          <Button
            onClick={props.onClickButton}
            color='forth'
            styles={{
              padding: '12px 20px',
              marginRight: '10px',
            }}>
            {props.labelButton}
          </Button>
          <Button color='fifth' onClick={props.onClose}>
            Cancelar
          </Button>
        </ContainerFooter>
      </Container>
    </Modal>
  );
}
