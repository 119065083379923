import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const ContainerBody = styled.div`
  width: 100%;
  height: 80%;
  overflow: auto;
  padding-left: 40px;
`;

export const ContainerCreate = styled.div`
  width: 60%;
  height: 100px;
  padding-top: 40px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerButton = styled.div`
  width: 70px;
  height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const ContainerInput = styled.div`
  width: 80%;
  height: 100px;
`;

export const ContainerList = styled.div`
  width: 100%;

  display: flex;
  flex-direction: column;
`;

export const Title = styled.h3`
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  margin-right: 20px;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 45px;
  background: #e4f1fa;
  border-radius: 8px;
  padding: 20px;
  margin: 4px 0;
  cursor: pointer;
`;

export const LabelCard = styled.label``;
