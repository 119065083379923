import React from 'react';
import { ContainerSchedule, Scheduled } from './styles';

type IBadge = {
  text?: string,
  textColor?: string,
  radius: string,
  color: string,
  widthHeigt?: string,
};

export const CircleBadge: React.FC<IBadge> = (props: IBadge) => {
  return (
    <>
      <ContainerSchedule color={props.color} itemProp={props.radius} itemType={props.widthHeigt}>
        <Scheduled color={props.textColor}>{props.text}</Scheduled>
      </ContainerSchedule>
    </>
  );
};
