import React, { useState } from 'react';
import { AttendantItem } from '../AttendantItem';
import { TitleSidebar } from '../TitleSidebar';
import { Container, DateContainer } from './styles';

export const ProcedureAttendantSidebar: React.FC = () => {
  const [show, setShow] = useState(false);

  const listAttendant = [
    { id: 1, name: 'Amanda Vasconcelos', role: 'Vítima', type: 'attendant' },
    { id: 2, name: 'José Luiz de Oliveira Pinto', role: 'Conduzido', type: 'attendant' },
    { id: 3, name: 'Mauro Gomes de Souza', role: 'Testemunha', type: 'attendant' },
    { id: 4, name: 'Melissa Coelho Menezes', role: 'Informante', type: 'attendant' },
    { id: 5, name: 'Carlos Alberto Brum de Sá', role: 'Advogado da vítima', type: 'lowyer' },
    { id: 6, name: 'Reginaldo Moreira Santana', role: 'Advogado do réu', type: 'lowyer' },
  ];

  return (
    <Container>
      <TitleSidebar
        titleSize={16}
        quantity={listAttendant.length}
        title='Participantes'
        onAddSidebar={() => alert('adicionando')}
        onShow={() => setShow(!show)}
        show={show}
      />
      {show && (
        <DateContainer>
          {listAttendant.map((item) => (
            <AttendantItem key={item.id} type={item.type} name={item.name} role={item.role} />
          ))}
        </DateContainer>
      )}
    </Container>
  );
};
