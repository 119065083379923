import React from 'react';
import { CircleBadge } from '../Badges/Circle';
import { Container, LabelContainer, Label, TextArea, ContainerTextArea } from './styles';

type ITextArea = {
  label?: string,
  required?: boolean,
  onChange: (e: string) => unknown,
  value?: string,
  style?: React.CSSProperties,
};

export const TextAreaComponent: React.FC<ITextArea> = ({ label, required = false, value, onChange, style }) => {
  return (
    <Container style={style}>
      <LabelContainer>
        <Label>{label}</Label>
        {required && <CircleBadge color='#e98d0b' radius='50px' widthHeigt='10px' />}
      </LabelContainer>
      <ContainerTextArea>
        <TextArea value={value} onChange={(e) => onChange(e.target.value)} />
      </ContainerTextArea>
    </Container>
  );
};
