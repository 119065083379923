import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 92.45vh;
  overflow-y: auto;
`;

export const Body = styled.div`
  display: flex;
  flex-direction: row;
  width: 75%;
`;
