import React, { ReactNode, useEffect, useState } from 'react';
import Modal from 'react-modal';
import close from '../../assets/svg/close.svg';

interface ModalType {
  children?: ReactNode;
  isOpen: boolean;
}

const styleImage = {
  top: 0,
  right: 0,
  margin: 26,
  cursor: 'pointer',
};

const customStyles = {
  content: {
    border: 'none',
    backgroundColor: '#FFF',
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    width: '100%',
    height: '100%',
    padding: 0,
    top: 0,
    left: 0,
  },
  overlay: {
    backgroundColor: '#FFF',
  },
};
export default function CustomModal(props: ModalType) {
  const [isOpen, setIsOpen] = useState(false);

  useEffect(() => {
    setIsOpen(props.isOpen);
  }, [props.isOpen]);

  return (
    <Modal ariaHideApp={false} isOpen={isOpen} style={customStyles} contentLabel='Example Modal'>
      <img alt=''src={close} style={{ ...styleImage, position: 'absolute' }} onClick={() => setIsOpen(false)} />
      {props.children}
    </Modal>
  );
}
