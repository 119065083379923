/* eslint-disable react-hooks/exhaustive-deps */
/* eslint-disable @typescript-eslint/no-explicit-any */
/* eslint-disable prettier/prettier */
import React, { useEffect, useRef, useState } from 'react';
import { useDispatch } from 'react-redux';
import { HeaderHearing } from '../../../features/HeaderHearing';
import ModalRight from '../../../components/ModalRight';
import HearingDetailsSidebar from '../../../features/HearingDetailsSidebar';
import { VideoPlayer } from '../../../features/VideoPlayer';

import { Container, Body } from './styles';
import api from '../../../services/api';
import { useToast } from '../../../hooks/toast';
import { MarkingChildrenModal } from '../../../features/MarkingChildren';
import { hideLoading, showLoading } from '../../../store/modules/login/actions';
import { useQuery } from '../../../utils/get';
import { ModalConfirmation } from '../../../components/ModalConfirmation';
import { markingService } from '../../../services/app/marking';
import { IHearingIndex } from '../../../services/app/marking/interface';

export interface IMarkingType  {
  toggleMarkingTime: (time: number) => unknown,
    toggleRate:(rate: number) => unknown,
    toggleVideoPlay: ()  => unknown,
    playerState: {
      playing: boolean,
      progress: number,
      currentTime: string,
      duration: string,
      secondsCurrentTime: number,
      secondsDuration: number,
      volume: number,
      playbackRate: number,
      marking: any,
    },
}

export const HearingDetails: React.FC = () => {
  const [marking, setMarking] = useState<IMarkingType>({
    toggleMarkingTime: () => ({}),
    toggleRate:(rate: number) => ({}),
    toggleVideoPlay: () => ({}),
    playerState: {
      playing: false,
      progress: 0,
      currentTime: '00:00:00',
      duration: '00:00:00',
      secondsCurrentTime: 0,
      secondsDuration: 0,
      volume: 1,
      playbackRate: 1,
      marking: null,
    },
  });
  const [subject, setSubject] = useState('');
  const [isEdit, setIsEdit] = useState(false);
  const [attendantId, setAttendantId] = useState<number>();
  const [procedureItem, setProcedureItem] = useState()
  const [isOpen, setIsOpen] = useState(false);
  const [showDeleteModal, setShowDeleteModal] = useState(false);
  const [itemMarking, setItemMarking] = useState<any>(null);
  const [markingList, setMarkingList] = useState<Array<any>>([]);
  const [attendantList, setAttendantList] = useState<Array<any>>([]);
  const [currentIndex, setCurrentIndex] = useState(-1);
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const query = useQuery();
  const manualTranscriptionRef = useRef<any>(null)

  const getIndexList = async () => {
    const { data } = await api.get(`/HearingIndex/GetAllByHearingId?hearingId=${query.get('hearingId')}`);
    
    if (data.data.length > 0) {
      const responseSorted = data.data.sort((a: any, b: any) => {
        return a.offSet > b.offSet ? 0 : -1;
      });
      setMarkingList(responseSorted);
    } else {
      setMarkingList(data.data);
    }
  };

  const getProcedureInformation = async () => {
    try {
      const { data } = await api.get(`/ProcedureCustomer/Get?id=${query.get('procedureId')}`);
      const procedureCustomerResp = data.data
      if(procedureCustomerResp) {
        const { data: customerSource } = await api.get(`/ProcedureCustomerSource/Get?id=${procedureCustomerResp.procedureCustomerSourceId}`);
        setProcedureItem({...data.data, procedureCustomerSourceName: customerSource.data.name})
      }
    } catch (error: any) {
      console.log(error.message)
    }
  }

  const closeModal = () => {
    setIsOpen(false);
    setIsEdit(false);
    setItemMarking(null)
    setCurrentIndex(-1)
    setSubject('');
  };

  const createNewMarking = async () => {
    try {
      if(!attendantId) {
        addToast({
          title: 'Criação de marcação',
          description: 'Para criar uma marcação é necessário selecionar um participante!',
          type: 'warning',
        });

        return
      }

      const body = {
        hearingId: Number(query.get('hearingId')),
        attendantId: attendantId,
        offset: marking.playerState.secondsCurrentTime,
        recordDate: new Date(),
        duration: marking.playerState.secondsDuration,
        subject: subject,
      };
      dispatch(showLoading());
      await api.post('/HearingIndex/Add', body);

      await getIndexList();

      closeModal();
      addToast({
        title: 'Criação de marcação',
        description: 'Sua marcação foi criada com sucesso!',
        type: 'success',
      });
      dispatch(hideLoading());
    } catch (error) {
      // eslint-disable-next-line no-lone-blocks
      {
        addToast({
          title: 'Error na marcação',
          description:
            'Houve um error ao criar a marcação, tente novamente mais tarde ou entre em contato com o suporte.',
          type: 'warning',
        });
        dispatch(hideLoading());
      }
    }
  };

  useEffect(() => {
    (async () => {
      try {
        dispatch(showLoading());
        await getIndexList();
        await getProcedureInformation()

        const { data: attendantListData } = await api.get(
          `ProcedureCustomerAttendant/GetAllNameByProcedureCustomerId?ProcedureCustomerId=${query.get('procedureId')}`,
        );

        setAttendantList(attendantListData.data);
        dispatch(hideLoading());
      } catch (error) {
        alert('erro ao carregar indexList');
      }
    })();
  }, []);

  const closeDeleteModal = () => {
    setShowDeleteModal(false);
    closeModal()
  };

  const onDeleteMarking = (item: any) => {
    setItemMarking(item);
    setShowDeleteModal(true);
  };

  const handleUpdateMarking = async () => {
    try {
      dispatch(showLoading());
      const body: IHearingIndex = {
        id: itemMarking.id,
        hearingId: itemMarking.hearingId,
        attendantId: attendantId as number,
        offset: marking.playerState.secondsCurrentTime,
        recordDate: new Date(),
        duration: marking.playerState.secondsDuration,
        subject: subject,
      };

      await markingService.update(body);
      await getIndexList();
      closeModal();

      dispatch(hideLoading());
      addToast({
        title: 'Atualização de marcação',
        description: 'A atualização da marcação foi feita com sucesso!',
        type: 'success',
      });
    } catch (error) {
      addToast({
        title: 'Atualização de marcação',
        description: 'Ocorreu um error ao atualizar sua marcação!',
        type: 'warning',
      });
    }
  };

  const onEditMarking = (item: any) => {
    setItemMarking(item);
    setIsOpen(true);
    setIsEdit(true);
    setSubject(item.subject)

    attendantList.forEach((element) => {
      if(element.name === item.name) {
        setAttendantId(element.id)
      }
    })
  };

  const deleteMarking = async () => {
    try {
      closeDeleteModal();
      dispatch(showLoading());
      await markingService.delete(itemMarking.id);
      await getIndexList();
      dispatch(hideLoading());
      addToast({
        title: 'Deletar marcação',
        description: 'Sua marcação foi deletada com sucesso!',
        type: 'success',
      });
    } catch (error) {
      dispatch(hideLoading());
      setCurrentIndex(-1);
      addToast({
        title: 'Deletar marcação',
        description: 'Ocorreu um error ao deletar sua marcação!',
        type: 'warning',
      });
    }
  };

  const getTranscription = async () => {
    try {
      dispatch(showLoading());
      const {data} = await api
      .get(`HearingTranscription/GetAllByHearingId?hearingId=${query.get('hearingId')}`)

      console.log('data', data)
      dispatch(hideLoading());
    } catch (error) {
      dispatch(hideLoading());
      addToast({type: 'warning', title: 'Recuperar transcrição', description: 'Não foi possível recuperar a transcrição'})
    }
  }

  useEffect(() => {
    getTranscription()
  },[])

  return (
    <Container>
      <HeaderHearing procedureItem={procedureItem}/>
      <Body>
        <VideoPlayer setMarking={setMarking} />
        <HearingDetailsSidebar
          manualTranscriptionRef={manualTranscriptionRef}
          procedureItem={procedureItem}
          onEditMarking={onEditMarking}
          onDeleteMarking={onDeleteMarking}
          dataMarking={markingList}
          setOpenModal={setIsOpen}
          marking={marking}
          setCurrentIndex={setCurrentIndex}
          currentIndex={currentIndex}
        />
      </Body>
      <ModalRight
        onClickAplicar={isEdit ? handleUpdateMarking : createNewMarking}
        setIsOpen={closeModal}
        title={isEdit ? 'Editar marcação' : 'Criar marcação'}
        isOpen={isOpen}
        onClickDelete={deleteMarking}
        isEdit={isEdit}>
        <MarkingChildrenModal
          isEdit={isEdit}
          setAttendantId={setAttendantId}
          attendantList={attendantList}
          subject={subject}
          setSubject={setSubject}
          toEditItem={itemMarking}
          marking={marking}
        />
      </ModalRight>
      <ModalConfirmation
        onClose={closeDeleteModal}
        title='Excluir marcação'
        titleBody='Deseja excluir a marcação?'
        subtitleBody='Esta ação não poderá ser desfeita'
        labelButton='Excluir'
        onClickButton={deleteMarking}
        isOpen={showDeleteModal}
      />
    </Container>
  );
};
