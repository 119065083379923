import React, { MutableRefObject } from 'react';
import { Editor } from '@tinymce/tinymce-react';
import { Container } from './styles';
import { editorInit } from './editor';
import { BottomMarkingTime, NewMarkingButton } from '../Marking/styles';
import { v4 } from 'uuid';
import { formatYmd } from '../../../utils/format';
import { transcription } from '../../../services/app/transcription';
import { useToast } from '../../../hooks/toast';
import { useDispatch } from 'react-redux';
import { hideLoading, showLoading } from '../../../store/modules/login/actions';

export interface ITranscriptionComponent {
  manualTranscriptionRef: MutableRefObject<any>;
}

export const TranscriptionComponent: React.FC<ITranscriptionComponent> = ({ manualTranscriptionRef }) => {
  const { addToast } = useToast();
  const dispatch = useDispatch();
  const user = localStorage.getItem('@Kenta:user');

  const passedUser = user ? JSON.parse(user) : null;

  const createManualTranscription = async () => {
    try {
      dispatch(showLoading());
      if (manualTranscriptionRef.current && passedUser) {
        const data = {
          id: 0,
          guid: v4(),
          hearingFileId: 194,
          userId: passedUser.id,
          customerTemplateId: 0,
          title: 'string',
          textPlain: manualTranscriptionRef.current.getContent(),
          autoTranscription: false,
          createIn: formatYmd(new Date()),
          updateIn: formatYmd(new Date()),
          status: 0,
          sendEmail: false,
          active: true,
        };

        await transcription.post('/HearingTranscription/add', data);

        dispatch(hideLoading());
        addToast({
          type: 'success',
          title: 'Transcrição salva!',
          description: 'Sua transcrição foi salva com sucesso!',
        });
      }
    } catch (error) {
      dispatch(hideLoading());

      addToast({
        type: 'warning',
        title: 'Erro na transcrição',
        description: 'Não foi possível criar a transcrição manual por favor tente novamente mais tarde!',
      });
    }
  };

  return (
    <>
      <Container>
        <Editor
          ref={manualTranscriptionRef}
          apiKey='qagffr3pkuv17a8on1afax661irst1hbr4e6tbv888sz91jc'
          onInit={(evt, editor) => (manualTranscriptionRef.current = editor)}
          init={editorInit}
          initialValue={'<strong>testando</strong>'}
        />
      </Container>
      <BottomMarkingTime>
        <NewMarkingButton width={91} onClick={createManualTranscription}>
          <strong>Salvar</strong>
        </NewMarkingButton>
      </BottomMarkingTime>
    </>
  );
};
