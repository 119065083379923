import styled from 'styled-components';

interface IProps {
  height: number;
}
interface IItemSelected {
  selected: boolean;
}

interface IButton {
  width?: number;
}

export const Container =
  styled.div <
  IProps >
  `
  height: ${(props) => props.height - 300}px;
  max-height: ${(props) => props.height - 300}px;
  overflow-y: auto;
`;

export const ItemContainer =
  styled.div <
  IItemSelected >
  `
  background: ${(props) => (props.selected ? '#ECF0F3' : '#FFF')};
  padding: 16px;

`;

export const HeaderContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  margin-bottom: 10px;
`;

export const HeaderTextContainer = styled.div`
  width: 100%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  border: 0;
  background: none;
`;

export const MarkingTime = styled.span`
  font-size: 12px;
  width: 60px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #e98d0b;
  cursor: pointer;
`;

export const MarkingTitle = styled.span`
  font-size: 12px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #000;
  margin-left: 5px;
`;
export const MarkingDescription = styled.p`
  font-size: 14px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  text-align: justify;
  padding: 5px 5px 0 5px;
`;

export const BottomMarkingTime = styled.div`
  display: flex;
  width: 100% - 24px;
  height: 100px;
  background: #d1dbe3;
  align-items: center;
  justify-content: flex-end;
  padding-right: 24px;
`;

export const NewMarkingButton =
  styled.button <
  IButton >
  `
  display: flex;
  align-items: center;
  justify-content: center;
  width: ${(props) => (props.width ? props.width : 169)}px;
  height: 40px;
  background: #1d9bf0;
  border-radius: 50px;
  border: 0;

  strong {
    font-family: 'Inter', Arial, Helvetica, sans-serif;
    color: #fff;
    font-size: 14px;
  }
`;

export const ContainerDelete = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;
`;
export const IconsContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: center;

  img {
    cursor: pointer;
    margin-left: 10px;
  }
`;
