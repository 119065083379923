import React from 'react';
import { Container, ContainerDateText, DateArea, Day, Hour, TextArea, Title, Description, IconArea } from './styles';
import iconEditCircle from '../../assets/procedure/edit_circle.svg';
import iconRecCircle from '../../assets/procedure/rec_circle.svg';

type IScheduleItemSidebar = {
  day: string,
  hour: string,
  title: string,
  description: string,
  onClick?: () => void,
};

export const ScheduleItemSidebar: React.FC<IScheduleItemSidebar> = ({ day, hour, title, description, onClick }) => {
  return (
    <Container className='schedule' onClick={onClick}>
      <ContainerDateText>
        <DateArea>
          <Day>{day}</Day>
          <Hour>{hour}</Hour>
        </DateArea>
        <TextArea>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TextArea>
      </ContainerDateText>
      <IconArea className='icons'>
        <img alt=''onClick={() => alert('editando')} src={iconEditCircle} />
        <img alt=''onClick={() => alert('gravando')} src={iconRecCircle} />
      </IconArea>
    </Container>
  );
};
