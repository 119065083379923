import React from 'react';
import { AddButton } from '../AddButton';
import { DeleteButton } from '../DeleteButton';
import { HeadeContainer, Title } from './styles';

export type IHeaderProcedure = {
  title?: string,
  AddButtonClick?: () => unknown,
  DeleteButtonClick?: () => unknown,
};

export const HeaderProcedure: React.FC<IHeaderProcedure> = ({ title, AddButtonClick, DeleteButtonClick }) => {
  return (
    <HeadeContainer>
      <Title>{title}</Title>
      <div>
        {AddButtonClick && <AddButton id='procedure_add_button' onClick={AddButtonClick} />}
        {DeleteButtonClick && (
          <DeleteButton id='procedure_delete_button' style={{ marginLeft: 16 }} onClick={DeleteButtonClick} />
        )}
      </div>
    </HeadeContainer>
  );
};
