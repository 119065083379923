import styled from 'styled-components';

export const Container = styled.div`
  width: 90%;
  height: 90%;
  display: flex;
  flex-direction: column;
  padding-left: 20px;
  padding-top: 40px;
`;
export const styleImage = {
  top: 0,
  right: 0,
  margin: 26,
  cursor: 'pointer',
};

export const ContainerHeader = styled.div`
  display: flex;
  flex-direction: column;
  height: 16%;
  justify-content: space-between;
  margin: 0px 0px 30px 0px;

  /* align-items: center; */
`;

export const ContainerMenu = styled.div`
  display: flex;
  flex-direction: column;
  border-bottom: 1px solid #ccc;
  border-top: 1px solid #ccc;
  padding-bottom: 20px;
  padding-top: 20px;
  margin-top: 20px;
  justify-content: space-between;
  height: 16%;
`;

export const TitleHeader = styled.h4`
  padding: 0px;
  margin: 0px;
`;

export const Subtitle = styled.span`
  font-size: 14px;
`;

export const TitleMenu = styled.h4`
  color: #7887a9;
  font-size: 16px;
  margin: 0px;
`;

export const Label = styled.label`
  font-size: 16px;
  cursor: pointer;
`;

export const customStyles = {
  content: {
    border: 'none',
    backgroundColor: '#FFF',
    width: '30%',
    height: '100%',
    inset: 'auto',
    padding: 0,
    top: 0,
    left: 0,
    borderRadius: 0,
    justifyContent: 'center',
    alignItems: 'center',
    // position: 'initial',
  },
  overlay: {
    backgroundColor: 'rgba(35, 35, 35, 0.75)',
    display: 'flex',
    justifyContent: 'flex-end',
  },
};
