import api from '../api';
import { HearingTypeInterface } from '../../pages/app/HearingType';



export const HearingTypeService = {
  postHearingType: async (authToken: string | null, hearingType: any) => {
    
    try {
      const body = {
        ...hearingType,
      };
      const { data } = await api.post<any>(`/HearingType/Add`, body);

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: 'Houve um erro ao cadastrar o registro.',
      };
    }
  },

  putHearingType: async (authToken: string | null, hearingType: any) => {
    // if (!authToken) {
    //   return {
    //     status: false,
    //     message: 'Não é possível editar o registro sem o token correto.',
    //   };
    // }

    try {
      const body = {
        ...hearingType,
      };
      console.log('body', body);
      const { data } = await api.put<HearingTypeInterface>(`/HearingType/Update`, body);
      console.log('puttt', data);

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      return {
        status: false,
        message: 'Houve um erro ao atualizar o registro.',
      };
    }
  },

  deleteHearingType: async (authToken: string | null, hearingTypeId: number) => {
    // if (!authToken) {
    //   return {
    //     status: false,
    //     message: 'Não é possível editar o registro sem o token correto.',
    //   };
    // }
    try {
      const body = [
        {
          op: 'replace',
          value: false,
          path: 'active',
        },
      ];

      const { data } = await api.patch(`/HearingType/update?id=${hearingTypeId}`, body);

      return {
        status: true,
        data: data,
      };
    } catch (error) {
      return {
        status: false,
        message: 'Houve um erro ao atualizar o registro.',
      };
    }
  },
};
