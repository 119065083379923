import React, { useState, useEffect } from 'react';
import { Dropdown, IDropDown } from '../../../components/Dropdown';
import { InputDate } from '../../../components/InputDate';
import { Container, ContainerInputs, ContainerInputsDate } from './styles';

import api from '../../../services/api';

interface HearingCreateEdit {
  setAttendantDropSelected: (e: number) => unknown;
  setHearingTypeDropSelected: (e: number) => unknown;
  setDateSelected: (e: Date) => unknown;
  setTimeSelected: (e: Date) => unknown;
  procedureCustomerId: any;
  //edit
  itemDataHearings?: any;
  isEditHearing: boolean;
  editAttendantName?: string;
  editHearingTypeName?: string;
}

export const CreateEditHearing = (props: HearingCreateEdit): JSX.Element => {
  const [dropDownHearingType, setDropDownHearingType] = useState<IDropDown[]>([]);
  const [dropDownAttendant, setDropDownAttendant] = useState<any[]>([]);

  const [dateEdit, setDateEdit] = useState('');
  const [timeEdit, setTimeEdit] = useState('');

  const user = localStorage.getItem('@Kenta:user');

  useEffect(() => {
    if (props.isEditHearing) {
      const [date, time] = props.itemDataHearings.expectedDate.split('T');
      setDateEdit(date);
      setTimeEdit(time);
    }
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  interface IAttedant {
    id?: number;
    name: string;
    procedureCustomerAttendantId?: number;
  }

  //Attendant
  useEffect(() => {
    (async () => {
      if (props.procedureCustomerId) {
        await api
          .get(
            `ProcedureCustomerAttendant/GetAllNameByProcedureCustomerId?ProcedureCustomerId=${props.procedureCustomerId.id}`,
          )
          .then((response) => {
            const { data } = response;

            const listAttedant: Array<IAttedant> = [];

            if (data.data.length) {
              data.data.forEach((element: IAttedant) => {
                const dataElement: IAttedant = {
                  id: element.procedureCustomerAttendantId,
                  name: element.name,
                };

                listAttedant.push(dataElement);
              });
            }

            setDropDownAttendant(listAttedant);
          })
          .catch((error) => console.log(error));
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  //HearingType;
  useEffect(() => {
    (async () => {
      if (props) {
        if (user) {
          const user_parsed = JSON.parse(user);
          await api
            .get(`/HearingType/GetAllByCustomerId?customerId=${user_parsed.customerId}`)
            .then((response) => {
              const onlyActives = response.data.data.filter((e: any) => e.active);
              setDropDownHearingType(onlyActives);
            })
            .catch((error) => console.log(error));
        }
      }
    })();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <Container>
      <ContainerInputs style={{ width: '84%', height: '15%' }}>
        <Dropdown
          labelName={'Participantes'}
          options={dropDownAttendant}
          setSelected={props.setAttendantDropSelected}
          editProcedure={props.editAttendantName}
        />
      </ContainerInputs>
      <ContainerInputs style={{ width: '84%', height: '15%' }}>
        <Dropdown
          labelName={'Tipo'}
          options={dropDownHearingType}
          setSelected={props.setHearingTypeDropSelected}
          editProcedure={props.editHearingTypeName}
        />
      </ContainerInputs>
      <ContainerInputsDate style={{ width: '85%', marginLeft: 10 }}>
        <InputDate
          type='date'
          default={dateEdit}
          label='Agendar para  '
          setDate={props.setDateSelected}
          required={true}
        />
        <InputDate type='time' default={timeEdit} label='Horário  ' setDate={props.setTimeSelected} required={true} />
      </ContainerInputsDate>
      <ContainerInputsDate
        style={{
          marginTop: 18,
        }}>
        {/* <Button color='third' onClick={teste}>
          Enviar convite por e-mail
        </Button> */}
      </ContainerInputsDate>
    </Container>
  );
};
