import React from 'react';
import { InputLabel } from '../../../../components/InputLabel';
import { Container, ContainerInputs } from './styles';

interface HearingTypeCreateEdit {
  onChangeName: (e: string) => unknown;
  valueEdit?: string;
}

export const CreateEditHearingType = (props: HearingTypeCreateEdit): JSX.Element => {
  return (
    <>
      <Container>
        <ContainerInputs>
          <InputLabel
            label={'Nome'}
            value={props.valueEdit}
            onChange={(e) => {
              props.onChangeName(e);
            }}></InputLabel>
        </ContainerInputs>
      </Container>
    </>
  );
};
