import React from 'react';
import { ProcedureItemSidebar } from '../ProcedureItemSidebar';
import { TitleSidebar } from '../TitleSidebar';
import { Container, DateContainer } from './styles';
import { format } from 'date-fns';

export type IProcedureDataSidebar = {
  itemData?: any,
  onEditItem?: () => void,
};

export const ProcedureDataSidebar: React.FC<IProcedureDataSidebar> = ({ itemData, onEditItem }) => {
  return (
    <Container>
      <TitleSidebar
        titleSize={18}
        title={`Procedimento ${itemData ? itemData.procedureNumber : '######'}`}
        onClickEdit={onEditItem}
      />
      <ProcedureItemSidebar title='Origem' description={itemData ? itemData.procedureCustomerSourceName : 'Nada'} />
      <DateContainer>
        <ProcedureItemSidebar
          title='Data de criação'
          description={itemData ? format(new Date(itemData.expectedDate), 'dd/MM/yyyy') : 'nada'}
        />
        <ProcedureItemSidebar
          title='Prazo'
          description={itemData ? format(new Date(itemData.deadLineDate), 'dd/MM/yyyy') : 'nada'}
        />
      </DateContainer>
    </Container>
  );
};
