import React, { useEffect, useState } from 'react';
import Modal from 'react-modal';
import close from '../../assets/svg/close.svg';
import { showLoading, hideLoading } from '../../store/modules/login/actions';
import ReactLoading from 'react-loading';
import {
  Container,
  ContainerHeader,
  ContainerMenu,
  customStyles,
  Label,
  Subtitle,
  TitleHeader,
  TitleMenu,
  styleImage,
} from './style';

import { useNavigate } from 'react-router-dom';
import { useDispatch } from 'react-redux';
import Avatar from 'react-avatar';
import { logout } from '../../services/auth';
import { PictureContainer } from '../HeaderUpMenu/styles';
import { storageBaseUrl } from '../../services/api';

interface ModalType {
  title?: string;
  isOpen: boolean;
  userName: string;
  profile: string;
  onClickDelete?: () => void;
  onClickAplicar?: () => void;
  onClose: () => void;
}
export const onClickSair = async ({ setIsLoading, dispatch, navigate }: any) => {
  dispatch(showLoading());
  setIsLoading(true);

  const token = localStorage.getItem('@Kenta:access_Token');
  if (token) {
    await logout(token);
  }

  navigate('/login');
  setIsLoading(false);
  dispatch(hideLoading());
};

export default function ProfileModal(props: ModalType) {
  const [isLoading, setIsLoading] = useState(false);
  const [userName, setUserName] = useState(props.userName);
  const [userItem, setUserItem] = useState<any>();
  const [unit, setUnit] = useState<any>();
  const navigate = useNavigate();
  const dispatch = useDispatch<any>();

  const searchUser = () => {
    const user = localStorage.getItem('@Kenta:user');
    const unitStorage = localStorage.getItem('@Kenta:unidade_selecionada');

    if (user !== '' && user) {
      setUserName(JSON.parse(user).name);
    }

    if (user) {
      const passedUser = JSON.parse(user);
      setUserItem(passedUser);
    }

    if (unitStorage) {
      setUnit(JSON.parse(unitStorage));
    }
  };

  useEffect(() => {
    if (props.isOpen === true) searchUser();
  }, [props.isOpen]);

  useEffect(() => {
    searchUser();
  }, []);

  return (
    <Modal
      testId='profile_modal_container'
      isOpen={props.isOpen}
      style={{ ...customStyles }}
      contentLabel='Example Modal'
      ariaHideApp={false}
      shouldCloseOnEsc={true}>
      <Container>
        <img alt=''src={close} style={{ ...styleImage, position: 'absolute' }} onClick={() => props.onClose()} />
        <ContainerHeader>
          {userItem && userItem.picture ? (
            <PictureContainer
              size={60}
              picture={`${storageBaseUrl}/${unit ? unit.containerName : null}/${userItem ? userItem.picture : null}`}
            />
          ) : (
            <Avatar name={userItem ? userItem.name : 'user test'} size='60' round={true} />
          )}
          <TitleHeader>{userName}</TitleHeader>
          <Subtitle>{props.profile}</Subtitle>
        </ContainerHeader>
        {isLoading ? (
          <ReactLoading type='spin' color='#F2A60D' width={24} height={24} />
        ) : (
          <>
            <Label>Minha conta</Label>
            <ContainerMenu>
              <TitleMenu>SUPORTE</TitleMenu>
              <Label>Base de conhecimento</Label>
              <Label>Meus chamados</Label>
            </ContainerMenu>
            <Label
              id='profile_modal_sair'
              onClick={() => onClickSair({ setIsLoading, dispatch, navigate })}
              style={{
                paddingTop: 10,
              }}>
              Sair
            </Label>
          </>
        )}
      </Container>
    </Modal>
  );
}
