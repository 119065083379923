import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
  background: #000;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`;

export const VideoContainer = styled.div`
  width: 100%;
  height: ${230}px;
`;

export const VideoPlayerComponent = styled.video`
  display: flex;
  flex-direction: column;
  width: 100%;
  height: ${230}px;

  &::-webkit-media-controls {
    display: none;
  }
`;
