import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  background: #2b2e38;
  padding: 0 25px 0 20px;
  width: 100% -45px;
  min-height: 80px;
  height: 80px;
`;

export const TitleContainer = styled.div`
  display: flex;
  flex-direction: row;

  justify-content: center;
  align-items: center;
`;

export const Title = styled.div`
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  margin-right: 20px;
`;

export const Body = styled.div`
  overflow-y: auto;
  height: 80vh;
`;
