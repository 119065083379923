import api from '../api';

export interface UserCustomerProfile {
  containerName: string;
  customer: string;
  customerId: number;
  id: number;
  expireIn: string;
  policeUnit: string;
  profile: string;
  profileId: number;
  root: string;
  uf: string;
  unitId: number;
  username: string;
}

type UserCustomerProfileResponse = {
  data: UserCustomerProfile[],
  errors: [],
  message: string,
  ok: boolean,
};

type UserProfileActionDTO = {
  action: string,
  id: number,
};

interface PermissionDataResponse {
  form: string;
  formId: number;
  id: number;
  listObjectUserProfileActionDTOs: UserProfileActionDTO[];
}

interface PermissionResponse {
  data: PermissionDataResponse[];
  errors: [];
  message: string;
  ok: boolean;
}

export const UserCustomerProfileService = {
  getAllByUserCustomer: async (authToken: string | null, userCustomerId: number) => {
    if (!authToken) {
      return {
        status: false,
        message: 'Não é possível recuperar os dados de perfil sem estar autenticado.',
      };
    }

    if (!userCustomerId) {
      return {
        status: false,
        message: 'Não é possível recuperar os dados de perfil sem o identificador do perfil',
      };
    }

    try {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` },
        params: { UserCustomerId: userCustomerId },
      };
      const { data } = await api.get<UserCustomerProfileResponse>(
        '/UserCustomerProfile/GetUserCustomerProfile',
        config,
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        error: error,
        message: 'Houve um erro ao recuperar os dados de perfil.',
      };
    }
  },
  /**
   *
   * @param authToken token of auth
   * @param customerId
   * @param userCustomerProfileId
   * @returns
   */
  getAllUserProfilePermissionsByParams: async (
    authToken: string | null,
    customerId: number | undefined,
    userCustomerProfileId: number,
  ) => {
    if (!authToken) {
      return {
        status: false,
        message: 'Não é possível recuperar os dados de permissões sem estar autenticado.',
      };
    }

    if (!customerId) {
      return {
        status: false,
        message: 'Não é possível recuperar os dados de permissões sem o usuario selecionado',
      };
    }

    if (!userCustomerProfileId) {
      return {
        status: false,
        message: 'Não é possível recuperar os dados de permissões sem o perfil selecionado',
      };
    }

    try {
      const config = {
        headers: { Authorization: `Bearer ${authToken}` },
        params: { UserCustomerProfileId: userCustomerProfileId, customerId },
      };
      const { data } = await api.get<PermissionResponse>(
        'SystemObjectUserCustomerProfile/GetAllByObjectsUserCustomerProfile',
        config,
      );

      return {
        status: true,
        data: data.data,
      };
    } catch (error) {
      return {
        status: false,
        message: 'Houve um erro ao recuperar os dados de perfil.',
      };
    }
  },
};
