import React, { useState, useEffect } from 'react';
import { AdminArea } from '../../../components/Body/AdminArea';
import api from '../../../services/api';
import ModalRight from '../../../components/ModalRight';
import { CreateEditHearingType } from './CreateEdit';
import { HearingTypeService } from '../../../services/hearingType';
import { stringFormat } from '../../../utils/stringFormat';

//Toast
import { hideLoading, showLoading } from '../../../store/modules/login/actions';
import { useDispatch } from 'react-redux';
import { useToast } from '../../../hooks/toast';

export interface HearingTypeInterface {
  active: boolean;
  customerId: number;
  id: number;
  name: string;
}

export function HearingType() {
  function onChangeInputSearch() {
    console.log('onChangeInputSearch');
  }

  const [listHearingType, setHearingType] = useState<any>([]);
  const [openModal, setOpenModal] = useState(false);
  const [isEdit, setIsEdit] = useState(false);
  const [nameHearingType, setNameHearingType] = useState<string>('');
  const [element, setElement] = useState<HearingTypeInterface>();
  const user = localStorage.getItem('@Kenta:user');

  const dispatch = useDispatch();
  const { addToast } = useToast();

  let customerId: any = null;

  if (user) {
    const user_parsed = JSON.parse(user);
    customerId = user_parsed.customerId;
  }

  useEffect(() => {
    onLoadList();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  function onLoadList() {
    (async () => {
      await api
        .get(`HearingType/GetAllByCustomerId?customerId=${customerId}`)
        .then((response) => {
          setHearingType(response.data.data);
        })
        .catch((error) => console.log(error));
    })();
  }

  function headerTrashButtonOnClick() {
    alert('clicou headerTrashButtonOnClick');
  }

  async function postHearingType() {
    if (!nameHearingType || stringFormat.isEmptyOrSpaces(nameHearingType)) {
      addToast({
        type: 'warning',
        title: 'Preencha o campo!',
      });
      return;
    }

    const existName = stringFormat.verifyExistNameInArray(listHearingType, nameHearingType);

    if (existName) {
      addToast({
        type: 'warning',
        title: 'Esse nome já existe!',
      });
      return;
    }

    dispatch(showLoading());

    setOpenModal(false);
    const resultPost = await HearingTypeService.postHearingType('', createBody());
    if (resultPost) {
      addToast({
        type: 'success',
        title: 'Tipo de oitiva criada com sucesso!',
      });
      clear();
      await onLoadList();
    } else {
      addToast({
        type: 'warning',
        title: 'Falha ao criar o tipo de oitiva!',
      });
    }

    dispatch(hideLoading());
  }

  async function putHearingType() {
    if (!nameHearingType || stringFormat.isEmptyOrSpaces(nameHearingType)) {
      addToast({
        type: 'warning',
        title: 'Preencha o campo!',
      });
      return;
    }

    const existName = stringFormat.verifyExistNameInArray(listHearingType, nameHearingType, element?.id);

    if (existName) {
      addToast({
        type: 'warning',
        title: 'Esse nome já existe!',
      });
      return;
    }

    dispatch(showLoading());

    setOpenModal(false);
    const resultPut = await HearingTypeService.putHearingType('', createBody());
    if (resultPut) {
      addToast({
        type: 'success',
        title: 'Tipo de oitiva editada com sucesso!',
      });
      clear();
      await onLoadList();
    } else {
      addToast({
        type: 'warning',
        title: 'Falha ao criar o tipo de oitiva!',
      });
    }
    dispatch(hideLoading());
  }

  async function deleteHearingType() {
    dispatch(showLoading());
    if (!element) {
      addToast({
        type: 'warning',
        title: 'Registro não encontrado',
      });
      return;
    } else {
      dispatch(showLoading());

      setOpenModal(false);
      const resultDelete = await HearingTypeService.deleteHearingType('', element.id);
      if (resultDelete) {
        addToast({
          type: 'success',
          title: 'Tipo de Oitiva inativada com sucesso!',
        });
        clear();
        await onLoadList();
      } else {
        addToast({
          type: 'warning',
          title: 'Falha ao inativar o tipo de oitiva!',
        });
      }

      dispatch(hideLoading());
    }
  }

  function createBody() {
    return {
      id: isEdit ? element?.id : 0,
      customerId: customerId,
      name: nameHearingType,
      active: isEdit ? element?.active : true,
    };
  }

  function onClickHearingType(item: HearingTypeInterface) {
    setIsEdit(true);
    setNameHearingType(item.name);
    setElement(item);
    setOpenModal(true);
  }

  function clear() {
    setIsEdit(false);
    setNameHearingType('');
    setElement(undefined);
    setOpenModal(false);
  }

  function openModalClearAdd() {
    clear();
    setOpenModal(true);
  }

  

  return (
    <>
      <AdminArea
        title={'Tipos de oitiva'}
        InputSearchLabel='Buscar por:'
        InputSearchOnChange={onChangeInputSearch}
        list={listHearingType}
        headerAddButtonOnClick={() => openModalClearAdd()}
        headerTrashButtonOnClick={headerTrashButtonOnClick}
        onClick={(item: any) => onClickHearingType(item)}
        setItemReturnClick={setElement}
        setOpenModal={setOpenModal}
        setIsEdit={setIsEdit}
      />
      <ModalRight
        isOpen={openModal}
        setIsOpen={() => clear()}
        isEdit={isEdit}
        title={isEdit ? 'Editando tipo de oitiva' : 'Criar tipo de oitiva'}
        onClickAplicar={isEdit ? putHearingType : postHearingType}
        showIconDelete={element?.active}
        onClickDelete={deleteHearingType}>
        <CreateEditHearingType onChangeName={setNameHearingType} valueEdit={nameHearingType} />
      </ModalRight>
    </>
  );
}
