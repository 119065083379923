import React from 'react';
import { Container, Title, EditContainer, ShowContainer, RightContainerSidebar } from './styles';
import bluePlusIcon from '../../assets/procedure/blue_plus.svg';
import editIcon from '../../assets/procedure/edit.svg';
import downArrow from '../../assets/procedure/down_arrow.svg';
import upArrow from '../../assets/procedure/up_arrow.svg';
import { QuantityComponent } from '../QuantityComponent';

export type ITitleSidebar = {
  title: string,
  titleSize?: number,
  quantity?: number,
  show?: boolean,
  onClickEdit?: () => unknown,
  onAddSidebar?: () => unknown,
  onShow?: () => unknown,
};

export const TitleSidebar: React.FC<ITitleSidebar> = ({
  title,
  titleSize,
  quantity,
  onClickEdit,
  onAddSidebar,
  onShow,
  show,
}) => {
  return (
    <Container>
      <Container>
        <Title size={titleSize}>{title}</Title>
        {quantity && <QuantityComponent value={quantity} />}
      </Container>
      <RightContainerSidebar>
        {onClickEdit && (
          <EditContainer id='procedure_edit_button' onClick={onClickEdit}>
            <img alt='' src={editIcon} />
          </EditContainer>
        )}
        {onAddSidebar && (
          <EditContainer onClick={onAddSidebar}>
            <img alt='' src={bluePlusIcon} />
          </EditContainer>
        )}

        {onShow && (
          <ShowContainer onClick={onShow}>
            <img alt='' src={show ? upArrow : downArrow} />
          </ShowContainer>
        )}
      </RightContainerSidebar>
    </Container>
  );
};
