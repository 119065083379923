import styled, { keyframes } from 'styled-components';

interface IProps {
  type: 'success' | 'warning' | 'error';
}

const color = {
  success: '#30CD82',
  warning: '#F0CA68',
  error: '#EB5861',
};

const appearFromLeft = keyframes`
  from {
    opacity: 0;
    position: absolute;
    transform: translateX(125%);
  }
  to{
    opacity: 1;
    position: relative;
    transform: translateX(0);
  }
`;

export const Container = styled.div`
  width: 300px;
  display: flex;
  justify-content: flex-end;
  padding: 4px;
  border-radius: 8px;
  background: ${(props: IProps) => color[props.type]};
  margin-bottom: 8px;
  animation: ${appearFromLeft} 0.5s;
`;

export const Content = styled.div`
  display: flex;
  flex-direction: column;
  width: 248px;
  border-radius: 0 8px 8px 0;
  background: #fff;
  padding: 24px;

  span {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 500;
    font-size: 12px;
    margin-top: 14px;
  }
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  strong {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

  img {
    cursor: pointer;
  }
`;
