import styled from 'styled-components';

export const Container = styled.div`
  width: 70%;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: flex-end;
  padding-right: 30px;
`;

export const DropDownContainer = styled('div')`
  width: 50%;
  cursor: pointer;
`;

export const DropDownHeader = styled('div')`
  /* margin-bottom: 0.8em; */
  /* padding: 0.6em 2.2em 0.6em 1em; */
  margin: 0;
  font-size: 14px;
  color: #3e424e;
  /* border: 2px solid #ccc; */
  border-radius: 4px;
  background: #ffffff;
`;

export const DropDownListContainer = styled('div')``;

export const DropDownList = styled('ul')`
  padding: 0;
  margin: 20px 0px 0px 0px;
  position: fixed;
  width: 15%;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #3e424e;
  font-size: 14px;
  font-weight: 500;
  &:first-child {
    padding-top: 0.8em;
  }
`;

export const ListItem = styled('li')`
  list-style: none;
  margin-bottom: 0.8em;
`;
