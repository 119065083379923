import styled from 'styled-components';

interface IProps {
  lowyer?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: row;
  width: 100%;
  margin-bottom: 11px;
`;

export const IconContainer = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 28px;
  height: 28px;
  border: 1.5px solid ${({ lowyer }: IProps) => (lowyer ? '#7887a9' : '#e98d0b')};
  border-radius: 50%;
  margin-right: 12px;
`;

export const AttendantContainer = styled.div`
  display: flex;
  flex-direction: column;
`;

export const Name = styled.div`
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #000;
`;

export const Role = styled.div`
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #666;
  margin-top: 4px;
`;
