import styled from 'styled-components';

interface IProps {
  error?: boolean;
}

export const Container = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 2px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const Label = styled.strong`
  font-style: normal;
  font-weight: 500;
  color: #7887a9;
  font-family: 'Inter';
  font-size: 14px;
  margin-right: 10px;
`;

export const Input = styled.input`
  border: ${(props: IProps) => (props.error ? '2px solid #e83845' : '2px solid #cccccc')};
  border-radius: 4px;
  height: 40px;
  font-size: 16px;
  padding: 0 10px;

  ::-webkit-inner-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
  ::-webkit-outer-spin-button {
    -webkit-appearance: none;
    margin: 0;
  }
`;
