import React from 'react';
import { useNavigate } from 'react-router-dom';
import backArrowIcon from '../../assets/hearingDetails/arrow-back.svg';
import editIcon from '../../assets/hearingDetails/edit_header.svg';
import editShare from '../../assets/hearingDetails/share.svg';

import {
  HeaderContainer,
  ProcedureHeaderContainer,
  BackButton,
  TitleContainer,
  Title,
  SubTitle,
  AttendantHeaderContainer,
  AttendantContainer,
  CircleIcon,
} from './styles';

type IHeaderHearing = {
  procedureItem: any,
};

export const HeaderHearing: React.FC<IHeaderHearing> = ({ procedureItem }) => {
  const navigate = useNavigate();

  return (
    <HeaderContainer>
      <ProcedureHeaderContainer>
        <BackButton>
          <img alt='' onClick={() => navigate(-1)} src={backArrowIcon} />
        </BackButton>
        <TitleContainer>
          <Title>{procedureItem ? 'Procedimento ' + procedureItem.procedureNumber : 'Carregando...'}</Title>
          <SubTitle>{procedureItem ? procedureItem.procedureCustomerSourceName : ''}</SubTitle>
        </TitleContainer>
      </ProcedureHeaderContainer>
      <AttendantHeaderContainer>
        <AttendantContainer>
          <TitleContainer>
            <Title>{procedureItem ? 'Mauro Gomes Fake' : 'Mauro Gomes de Souza'}</Title>
            <SubTitle>Testemunha</SubTitle>
          </TitleContainer>
          <CircleIcon padding={4} size={24}>
            <img alt='' src={editIcon} />
          </CircleIcon>
        </AttendantContainer>
        <CircleIcon padding={8} size={40}>
          <img alt=''  src={editShare} />
        </CircleIcon>
      </AttendantHeaderContainer>
    </HeaderContainer>
  );
};
