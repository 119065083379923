import styled from 'styled-components';

export const Container = styled.div`
  width: 100%;
`;

export const HeaderContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  width: 100% - 48px;
  height: 80px;
  min-height: 80px;
  padding: 0 24px;
  background: #3e424e;
`;

export const TitleContainer = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: space-between;

  strong {
    font-family: 'Inter';
    font-style: normal;
    font-weight: 600;
    font-size: 14px;
  }

  img {
    cursor: pointer;
  }
`;

export const Title = styled.h3`
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
  margin-right: 20px;
  padding-right: 30px;
`;

export const StyleDelete = {
  border: 1,
  borderStyle: 'solid',
  borderColor: '#1D9BF0',
  borderRadius: '20px',
  marginRight: 14,
  cursor: 'pointer',
  padding: 4,
};

//Body

export const ContainerBody = styled.div`
  width: 100%;
  height: 80%;
  overflow: auto;
  padding-left: 40px;
`;

export const ContainerSearch = styled.div`
  width: 60%;
  height: 100px;
  padding-top: 40px;
  padding-left: 10px;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
`;

export const ContainerInput = styled.div`
  width: 80%;
  height: 100px;
`;

export const ContainerOptions = styled.div`
  width: 70px;
  height: 100px;
  justify-content: center;
  align-items: center;
  display: flex;
`;

export const ContainerList = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
`;

export const CardContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 80%;
  height: 45px;
  background: #e4f1fa;
  border-radius: 8px;
  padding: 20px;
  margin: 4px 0;
  cursor: pointer;
`;

export const LabelCard = styled.label``;

//List

export const ListContainer = styled.div`
  width: 92.5%;
  padding: 15px 10px 15px 10px;
`;

export const ItemContainer = styled.div`
  height: 50px;
  width: 100%;
  display: flex;
  flex-direction: row;
  margin-bottom: 16px;
  align-items: center;
  justify-content: space-between;
  border: 2px solid #ecf0f3;
  background-color: #ecf0f3;
  padding: 4px 16px 4px 4px;
  border-radius: 8px;

  &:hover {
    border: 2px solid #1d9bf0;
    opacity: 1;
    cursor: pointer;
  }
`;

export const ContainerCheckBoxAndText = styled.div`
  width: 50%;
  display: flex;
  flex-direction: row;
  align-items: center;
`;

export const CheckBoxDiv = styled.div`
  padding-left: 3%;
  padding-right: 3%;
`;

export const TextArea = styled.div`
  width: 100%;
`;
