import styled from 'styled-components';

interface IProps {
  error?: boolean;
}

export const DropDownContainer = styled('div')`
  width: 100%;
`;

export const Label = styled.label`
  width: 100%;
  font-style: normal;
  font-weight: 500;
  /* line-height: 15px; */
  color: #7887a9;
  /* padding: 6px; */
  font-family: 'Inter';
  font-size: 14px;
  color: #7887a9;
`;

export const DropDownHeader = styled('div')`
  margin-bottom: 0.8em;
  padding: 0.6em 2.2em 0.6em 1em;
  font-weight: 500;
  margin: 0;
  font-size: 18px;
  color: gray;
  border: ${(props: IProps) => (props.error ? '2px solid #e83845' : '2px solid #cccccc')};
  border-radius: 4px;
  background: #ffffff;
`;

export const DropDownListContainer = styled('div')``;

export const DropDownList = styled('ul')`
  padding: 0;
  margin: 0;
  position: fixed;
  width: 36%;
  max-height: 200px;
  padding-left: 1em;
  background: #ffffff;
  border: 2px solid #e5e5e5;
  box-sizing: border-box;
  color: #7887a9;
  font-size: 18px;
  font-weight: 500;
  cursor: pointer;
  &:first-child {
    padding-top: 0.8em;
  }

  overflow-y: auto;
`;

export const ListItem = styled('li')`
  list-style: none;
  margin-bottom: 0.8em;
`;
