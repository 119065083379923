import styled, { css } from 'styled-components';

interface IProps {
  error: boolean;
  notError: boolean;
}

export const Container = styled.div`
  width: 100%;
  margin-bottom: 22px;
`;

export const Label = styled.span`
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 12px;
  line-height: 140%;
  color: #666;
`;

export const ContainerInput =
  styled.div <
  IProps >
  `
  display: flex;
  align-items: center;
  justify-content: center;
  width: 100% -20px;
  height: 48px;
  border: 2px solid #d1dbe3;
  border-radius: 4px;
  
  ${(props) =>
    props.error &&
    css`
      border: 2px solid #eb5861;
    `}

  ${(props) =>
    props.notError &&
    css`
      border: 2px solid #d1dbe3;
    `}
`;

export const InputText = styled.input`
  width: 100%;
  padding: 5px 10px;
  height: 37px;
  border: none;
  outline: none;
  font-size: 14px;
  font-family: 'Inter';
  font-style: normal;
  font-weight: 400;
  font-size: 14px;
  line-height: 20px;
`;

export const ContainerIcon = styled.button`
  display: flex;
  align-items: center;
  justify-content: center;
  background: none;
  border: none;
  padding-right: 17px;
  padding-left: 17px;
`;
