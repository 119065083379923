import styled from 'styled-components';

export const Container = styled.div`
  display: flex;
  align-items: center;
  justify-content: center;
  width: 24px;
  height: 24px;
  background: #e98d0b;
  border-radius: 50%;
`;

export const Value = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 500;
  color: #fff;
  font-size: 12px;
`;
