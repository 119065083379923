import React from 'react';
import {
  Container,
  ContainerDateText,
  ImgDiv,
  TextArea,
  Title,
  Description,
  InfoArea,
  DateLabel,
  DateBlackDiv,
  DateBlackSpan,
} from './styles';

import videoIcon from '../../../assets/svg/VideoIcon.svg';
import { StatusBadge } from '../../Badges/Status';

type IScheduleItem = {
  id: number,
  name: string,
  item: object,
  typeAttendant: string,
  date: string,
  timeVideo: string,
  statusText: string,
  statusType: string,
  openModal: boolean,
  setOpenModal: (hearingItem: object) => any,
  onDeleteItem: (id: number) => unknown,
  onClick?: () => unknown,
};

export const HearingItem: React.FC<IScheduleItem> = ({
  name,
  item,
  setOpenModal,
  typeAttendant,
  date,
  timeVideo,
  statusText,
  statusType,
}) => {
  return (
    <Container className='schedule' onClick={() => setOpenModal(item)}>
      <ContainerDateText>
        <ImgDiv>
          <img alt=''src={videoIcon}></img>
        </ImgDiv>
        <TextArea>
          <Title>{name}</Title>
          <Description>{typeAttendant}</Description>
        </TextArea>
      </ContainerDateText>
      <InfoArea className='icons'>
        <DateLabel>{date}</DateLabel>
        <DateBlackDiv>
          <DateBlackSpan>{timeVideo}</DateBlackSpan>
        </DateBlackDiv>
        <StatusBadge text={statusText} type={statusType}></StatusBadge>
      </InfoArea>
    </Container>
  );
};
