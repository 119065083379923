import styled from 'styled-components';

export const Container = styled.div`
  width: 25%;
  height: 100%;
  background-color: #ecf0f3;
`;
export const ContainerMenu = styled.div`
  padding-left: 14px;
  padding-top: 10px;
`;

export const DateContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  width: 100%;
  margin-top: 10px;
`;

export const MenuItem = styled.a`
  text-decoration: none;
  font-size: 14px;
  padding: 8px;
  cursor: pointer;
  color: #000;
`;

export const HeadeContainer = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  background: #3e424e;
  width: 100%;
  min-height: 80px;
`;

export const Title = styled.div`
  width: 200px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 700;
  font-size: 18px;
  color: #fff;
`;
