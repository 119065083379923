import React from 'react';
import { useSelector } from 'react-redux';
import ReactLoading from 'react-loading';

import { Container, ContainerGeral } from './styles';

export const LoadingToast: React.FC = () => {
  const { loading } = useSelector((state: any) => state.loading);
  if (loading) {
    return (
      <>
        <ContainerGeral />
        <Container>
          <span>Carregando...</span>
          <ReactLoading type='spin' color='#F2A60D' width={24} height={24} />
        </Container>
      </>
    );
  }

  return <div />;
};
