import styled from 'styled-components';

export const Container = styled.button`
  width: 40px;
  height: 40px;
  background: none;
  border-radius: 50%;
  border: 3px #1d9bf0 solid;
  cursor: pointer;
`;

export const PlusIcon = styled.span`
  display: flex;
  align-items: center;
  justify-content: center;
  color: #fff;
  font-size: 20px;
`;
