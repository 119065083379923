import React from 'react';
import {
  Container,
  AssetsContainer,
  TitleContainer,
  Title,
  Description,
  Checkbox,
  DateContainer,
  DateTitle,
  Scheduled,
  ContainerSchedule,
  TextContainer,
} from './styles';
import check from '../../assets/procedure/check.svg';

export type IProcedureItemProps = {
  selected?: boolean,
  title: string,
  description: string,
  date: string,
  isAgended: boolean,
  onClick?: () => unknown,
};

export const ProcedureItem: React.FC<IProcedureItemProps> = ({
  selected = false,
  title,
  description,
  isAgended,
  date,
  onClick,
}) => {
  return (
    <Container onClick={onClick} selected={selected}>
      <TextContainer>
        <AssetsContainer>
          <Checkbox selected={selected}>{selected && <img alt=''src={check} />}</Checkbox>
        </AssetsContainer>
        <TitleContainer>
          <Title>{title}</Title>
          <Description>{description}</Description>
        </TitleContainer>
      </TextContainer>
      {isAgended && (
        <DateContainer>
          <DateTitle>{date}</DateTitle>
          <ContainerSchedule>
            <Scheduled>AGENDADO</Scheduled>
          </ContainerSchedule>
        </DateContainer>
      )}
    </Container>
  );
};
