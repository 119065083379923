import React, { useEffect } from 'react';
import { Navigate } from 'react-router-dom';
import { HeaderUpMenu } from '../components/HeaderUpMenu';
import { useToast } from '../hooks/toast';
import api from '../services/api';
import { logout } from '../services/auth';

type Props = {
  children: JSX.Element,
};

export function ProtectedRoute({ children }: Props) {
  const user = localStorage.getItem('@Kenta:user');
  const { addToast } = useToast();

  useEffect(() => {
    const intervalId = setInterval(() => {
      (async () => {
        if (user) {
          try {
            const userPassed = JSON.parse(user);

            const { data } = await api.put(
              `/UserCustomerAccess/UpdateUserCustomerActiveAccess?hash=${userPassed.hash}`,
            );

            if (!data.ok) {
              await logout(userPassed.hash);
              localStorage.clear();
              return <Navigate to='/' />;
            }
            console.log('error', data);
          } catch (error: any) {
            addToast({
              type: 'warning',
              title: 'Sua conexão foi suspensa faça o login novamente!',
            });
            localStorage.clear();
            return <Navigate to='/' />;
          }
        }
      })();
    }, 30000);

    return () => clearInterval(intervalId);
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  // user is not authenticated
  if (children && !user) {
    return <Navigate to='/' />;
  }

  return (
    <>
      <HeaderUpMenu />
      {children}
    </>
  );
}
