import styled from 'styled-components';

export const Container = styled.div`
  width: 100% -20px;
`;

export const LabelContainer = styled.div`
  display: flex;
  flex-direction: row;
  justify-content: flex-start;
  align-items: center;
  margin-bottom: 4px;
`;

export const Label = styled.label`
  font-style: normal;
  font-weight: 500;
  /* line-height: 15px; */
  color: #7887a9;
  /* padding: 6px; */
  font-family: 'Inter';
  font-size: 14px;
  color: #7887a9;
  margin-right: 10px;
`;

export const ContainerTextArea = styled.div`
  width: 100% -20px;
  border: 2px solid #cccccc;
  padding: 10px;
  border-radius: 8px;
`;

export const TextArea = styled.textarea`
  resize: none;
  height: 20vh;
  outline: none;
  border: 0;
`;
