import styled from 'styled-components';

export const Container = styled.div`
  margin-top: 24px;
  width: 100%;
`;

export const Title = styled.div`
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 400;
  font-size: 12px;
  color: #666;
`;

export const Description = styled.div`
  margin-top: 8px;
  font-family: 'Inter', Arial, Helvetica, sans-serif;
  font-weight: 600;
  font-size: 12px;
  color: #000;
`;
