import React, { useEffect } from 'react';
import api from '../../services/api';
import { AddButton } from '../AddButton';
import { HearingList } from '../Hearing/List';
import { QuantityComponent } from '../QuantityComponent';
import { Container, HeaderContainer, Title, TitleContainer, Body } from './styles';
import { useToast } from '../../hooks/toast';

export type IHeaderHearing = {
  title?: string,
  openModal: boolean,
  addButtonClick?: () => unknown,
  procedureItem: null | any,
  setItemData: (data: any) => any | null,
  setIsEditHearing: (attribute: boolean) => any,
  setOpenModal: (openModal: boolean) => unknown,
  reloadList: boolean,
  setItemDataHearing: (dataHearing: any) => any | null,
  getAllHearing: () => unknown,
  hearingList: Array<any>,
};

export const Hearing: React.FC<IHeaderHearing> = ({
  openModal,
  procedureItem,
  title,
  addButtonClick,
  setItemData,
  setIsEditHearing,
  setOpenModal,
  setItemDataHearing,
  getAllHearing,
  hearingList,
}) => {
  const { addToast } = useToast();

  useEffect(() => {
    getAllHearing();
  // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [procedureItem]);

  const onDeleteItem = async (id: number) => {
    setOpenModal(false);

    const body = [
      {
        op: 'replace',
        value: false,
        path: 'active',
      },
    ];

    await api.patch(`/hearing/update?id=${id}`, body);

    addToast({
      type: 'success',
      title: 'Registro excluído!',
      description: 'O registro de oitiva foi excluído com sucesso',
    });
  };

  const clickHearing = (hearingItem: object) => {
    setOpenModal(true);
    setItemDataHearing(hearingItem);
  };

  return (
    <Container>
      <HeaderContainer>
        <TitleContainer>
          <Title>{title}</Title>
          <QuantityComponent value={hearingList.length || 0} id='hearing_quantity' />
        </TitleContainer>
        {addButtonClick && procedureItem && <AddButton id='hearing_add_button' onClick={addButtonClick} />}
      </HeaderContainer>
      <Body>
        {procedureItem && (
          <HearingList
            setIsEditHearing={setIsEditHearing}
            setItemData={setItemData}
            setOpenModal={clickHearing}
            openModal={openModal}
            onDeleteItem={onDeleteItem}
            hearingList={hearingList}
          />
        )}
      </Body>
    </Container>
  );
};
