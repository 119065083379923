import React, { useState } from 'react';
import {} from 'styled-components';
import { Container, DateInput, Label } from './style';
import { CircleBadge } from '../Badges/Circle';

interface InputDateProps {
  label?: string;
  default?: any;
  type?: string;
  setDate: (e: any) => any;
  required?: boolean;
}

export const InputDate = (props: InputDateProps) => {
  const [value, setValue] = useState();
  const [error, setError] = useState(false);

  const onChangeValue = (valueChange: any) => {
    props.setDate(valueChange);
    setValue(valueChange);
  };

  const onBlur = () => {
    if (!value && props.required) {
      return setError(true);
    }

    return setError(false);
  };

  return (
    <Container>
      <Label>
        {props.label ? props.label : 'title:'}
        {props.required === true ? <CircleBadge color='#e98d0b' radius='50px' widthHeigt='10px' /> : ''}
      </Label>
      <DateInput
        defaultValue={props.default}
        error={error}
        type={props.type === null ? 'date' : props.type}
        onBlur={onBlur}
        onChange={(e) => onChangeValue(e.target.value)}></DateInput>
    </Container>
  );
};
